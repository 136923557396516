export default {
	messages: {
		ru: {
			'Город': 'Город',
			'выберите город': 'выберите город',
			'Сначала выберите регион': 'Сначала выберите регион'
		},
		uk: {
			'Город': 'Місто',
			'выберите город': 'оберіть місто',
			'Сначала выберите регион': 'Спочатку виберіть регіон'
		},
	}
};
