import { mapActions } from 'vuex';

export default {
	data() {
		return {
			currentBrand: this.brandId,
			currentOptions: [],
			currentModel: 0,
		};
	},
	mounted() {
		this.$root.$on('resetFilter', () => {
			this.currentOptions = [];
		});
	},
	name: 'Model',
	props: ['index', 'brandId'],
	watch: {
		brandId: function (data) {
			this.filterModelByBrand(data)
				.then((models)=> {
					this.currentOptions = models;
				});
		},
	},
	computed: {
		selected: {
			get() {
				return this.currentModel || 0;
			},
			set(value) {
				this.setModel({value, index: this.index});
			}
		},
		options() {
			return Object.values(this.currentOptions);
		}
	},
	methods: {
		...mapActions({
			setModel: 'cabinet/advertisements/filter/model',
			filterModelByBrand: 'cabinet/advertisements/filter/filterModelByBrand'
		})
	},
	i18n: {
		messages: {
			uk: {
				Выберите: 'Виберіть'
			},
			ru: {
				Выберите: 'Выберите'
			}
		}
	}
};
