export default {
	messages: {
		ru: {
			'Ещё марка': 'Ещё марка',
			'Марка, модель, год': 'Марка, модель, год',
			'Исключить марку': 'Исключить марку'
		},
		uk: {
			'Ещё марка': 'Ще марка',
			'Марка, модель, год': 'Марка, модель, рік',
			'Исключить марку': 'Виключити марку'
		},
	}
};
