export default {
	messages: {
		ru: {
			'Тип привода': 'Тип привода'
		},
		uk: {
			'Тип привода': 'Тип приводу'
		},
	}
};
