export default {
    messages: {
        uk: {
            'Обрати': 'Обрати',
        },
        ru: {
            'Обрати': 'Выбрать',
        },
    }
}
