export default {
	messages: {
		ru: {
			'Исключить авто': 'Исключить авто',
			'Скрыть': 'Скрыть'
		},
		uk: {
			'Исключить авто': 'Виключити авто',
			'Скрыть': 'Сховати'
		},
	}
};
