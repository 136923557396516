import { mapGetters, mapMutations } from 'vuex';
import options from "./options";

export default {
	name: 'FilterByChecking',
	i18n: require('./i18n').default,
	data() {
		return {
			options
		}
	},
	computed: {
		...mapGetters({
			filterByChecking: 'search/state2/getFilterByChecking',
			getValue: 'search/state2/getValue',
		}),
		locations() {
			return this.getValue({
				name: 'locations'
			}).map(item => item ? item : {});
		},
		regionVinnytsia() {
			const regionValues = this.locations.map(item => item.region);
			return regionValues.find(number => number === 1);
		},
		value: {
			get: function () {
				return this.filterByChecking;
			},
			set: function (value) {
				this.setFilterByChecking(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setFilterByChecking: 'search/state2/setFilterByChecking'
		}),
	},
};
