import {mapActions, mapGetters, mapMutations} from 'vuex'
import translate from "./i18n";
import series from "../../../../Cabinet/Advertisements/Filter/Series";
import getModificationKey from "../../../../../store/shared/modification/helpers/getModificationKey";

export default {
    name: 'GenerationField',
    components: {
        'model-generations': require('./ModelGenerations/index.vue').default,
        'select-field-wrapper': require('../../../../Common/SelectFieldWrapper/index.vue').default,
    },
    props: {
        indices: {
            type: Array,
            required: true
        },
        blockIndex: {
            type: Number,
            required: true
        },
        brandId: {
            type: Number | String,
            required: true
        },
        models: {
            type: Array,
            required: true
        },
        years: {
            type: Object,
            required: true
        },
        exclude: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            getByIndex: 'search/state2/getByIndex',
            getValue: 'search/state2/getValue',
        }),
        ...mapGetters('shared/generation', {
            allGenerationsData : 'allGenerationsData',
            generationsByIndexBlock: 'generationsByIndexBlock',
        }),
        ...mapGetters('shared/modification', {
            allModificationsData : 'allModificationsData',
        }),
        series: function () {
            return this.getValue({
                name: 'series'
            });
        },
        generations() {
            return this.generationsByIndexBlock(this.blockIndex, this.exclude, this.brandId);
        },
        selected(){
            const allGenerationIds = this.indices.reduce((acc, seriesIndex) => {
                const series = this.getByIndex({
                    name: 'series',
                    index: seriesIndex
                }) || {};
                if (series.hasOwnProperty('generation')) acc = [...acc, ...series.generation];
                return acc;
            }, []);

            return allGenerationIds.length ? allGenerationIds : [];
        },
        selectedGenerationsNames() {
            return Object.values(this.generations).reduce((acc, model) => {
                model.generations.forEach(item => {
                    if(this.selected.includes(item.generationId)) acc.push(item.name);
                })
                return acc;
            },[]).join(', ');
        },
        excludePrefix() {
            return this.exclude ? '-not' : '';
        },
        idSelectBackground(){
            return `generation-${this.blockIndex}-${this.brandId}${this.excludePrefix}`;
        },
        idSelect(){
            return `generation-${this.blockIndex}${this.excludePrefix}`;
        }
    },
    methods: {
        ...mapActions('shared/generation', {
            fetchGeneration: 'fetchGeneration',
            addGenerationToSeries: 'addGenerationToSeries',
            deleteGenerationFromSeries: 'deleteGenerationFromSeries',
        }),
        ...mapActions('shared/modification', {
            fetchModifications: 'fetchModifications',
            deleteModificationLists: 'deleteModificationList'
        }),
        async select({target, generationData}) {
            const {blockIndex, brandId, exclude} = this;
            const { name: fieldName } = target; // назва основного поля в обʼекті критеріїв пошуку
            const { modelId, generationId } = generationData; // данні покоління
            const {seriesIndex, ...seriesData} = this.getSeriesDataByModelId(modelId);  // данний обʼєкт критеріїв пошуку по (бренду, моделі, року, поколінню)

            // Якщо поля покоління немає в обʼєкті або дане поління не вказане в полі покоління - то ми додаємо вибране покління
            if(!seriesData.hasOwnProperty(fieldName) || !seriesData[fieldName].includes(generationId)) {
                this.addGenerationToSeries({seriesData, seriesIndex, fieldName, generationId});
            }
            // В іншому випадку ми видаляємо дане покоління - або тільки id або повністю поле
            else {
                this.deleteGenerationFromSeries({seriesData, seriesIndex, fieldName, generationId});
            }
        },
        getSeriesDataByModelId(modelId){
            const foundSeries = this.indices.reduce((acc, seriesIndex) => {
                const series = this.getByIndex({
                    name: 'series',
                    index: seriesIndex
                }) || {};
                if( series.hasOwnProperty('model') &&
                    series.model === modelId &&
                    Boolean(this.exclude) === Boolean(series.not)
                ) acc.push({...series, seriesIndex});
                return acc;
            }, []);
            if(!foundSeries.length) return null;
            return foundSeries[0];
        },
        refreshAllGeneration(){
            const { blockIndex, brandId, years } = this;
            this.models.forEach( async (modelId) => {
                const {seriesIndex, ...seriesData} = this.getSeriesDataByModelId(modelId); // данний обʼєкт критеріїв пошуку по (бренду, моделі, року, поколінню)
                // запитуємо нові покоління з урахування вказаних років
                const fetchedGenerations = await this.fetchGeneration({blockIndex , brandId, modelId, years, exclude: this.exclude});
                if(seriesData.hasOwnProperty('generation') && seriesData.generation.length){
                    seriesData.generation.forEach((el) => {
                        // видаляємо покоління якщо вони не є в новому списку поколінь
                        if(!fetchedGenerations.includes(el)) {
                            this.deleteGenerationFromSeries({seriesData, seriesIndex, fieldName: 'generation', el});
                        }
                    })
                }
            });
        },
        getGenerationDataById(generationId) {
            return Object.values(this.generations).find(({generations}) =>
                Boolean(generations.find(
                        el => el.generationId === generationId
                    )
                )
            );
        }
    },
    i18n: {
        ...translate
    },
    watch: {
        years: {
            handler(newYears, oldYears){
                if (
                    (newYears.gte !== oldYears.gte)
                    || (newYears.lte !== oldYears.lte)
                ) {
                    this.refreshAllGeneration();
                }
            },
            deep: true
        },
    }
}
