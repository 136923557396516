export default {
	messages: {
		ru: {
			'Выберите марку': 'Выберите марку',
			'Просмотренные': 'Просмотренные',
			'ТОП марки': 'ТОП марки',
			'Все марки': 'Все марки'
		},
		uk: {
			'Выберите марку': 'Оберіть марку',
			'Просмотренные': 'Переглянуті',
			'ТОП марки': 'ТОП марки',
			'Все марки': 'Всі марки'
		},
	}
};
