export default {
  messages: {
    ru: {
      'Пробег, тыс. км': 'Пробег, тыс. км',
      От: 'От',
      До: 'До',
      'Без пробігу': 'Без пробега',
    },
    uk: {
      'Пробег, тыс. км': 'Пробіг, тис. км',
      От: 'Від',
      До: 'До',
      'Без пробігу': 'Без пробігу',
    },
  },
};
