import {mapGetters, mapActions, mapMutations} from 'vuex';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';

export default {
	init({store, target}) {
		if (target === 'node') {
			const category = store.getters['search/state2/getValue']('categories.main.id');
			return Promise.all([
				store.dispatch('shared/brands/fetchByCategory', category),
				store.dispatch('shared/brands/fetchPopularByCategory', category)
			].map(item => item.catch((error) => console.error(error.message))));
		}
	},
	name: 'Brand',
	i18n: require('./i18n').default,
	components: {
		Autocomplete: require('../../../Autocomplete/index.vue').default
	},
	props: ['indices', 'exclude', 'blockIndex'],
	data: function () {
		return {
			input_: '',
			mode: 'list',
			isOptionsHidden: true,
			suggesting: '',
			selectedName: '',
			resetInput: false,
			placeholder: `${this.$t('Выберите марку')}`,
			mounted: false
		};
	},
	methods: {
		...mapActions({
			fetch: 'shared/brands/fetchByCategory',
			fetchPopular: 'shared/brands/fetchPopularByCategory',
			suggest: 'shared/brands/suggest'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex',
			delByIndex: 'search/state2/delByIndex'
		}),
		init: function () {
			return Promise.all([
					this.fetch(this.category),
					this.fetchPopular(this.category)
				].map(item => item.catch((error) => console.error(error.message)))
			);
		},
		selectAutocomplite(brand) {
			brand = Number(brand) || 0;

			let indices = this.indices.sort((a, b) => a - b);
			let index = indices.shift();
			this.delByIndex({
				name: 'series',
				indices: indices
			});

			let value = this.getByIndex({
				name: 'series',
				index
			}) || {};
			delete value.model;
			delete value.generation;
			if (brand) {
				value.brand = brand;
			} else {
				delete value.brand;
			}
			if (this.exclude) {
				value.not = true;
			}
			this.addByIndex({
				name: 'series',
				index,
				value
			});
			this.mode = 'list';
		},
		inputAutocomplite(value) {
			this.input_ = value;
			if (value || (this.brand && !value)) {
				this.mode = 'autocomplete';
				this.suggest({text: value, category: this.category});
			} else {
				this.mode = 'list';
			}
		},
		escAutocomplite() {
			this.mode = 'list';
		}
	},
	mounted: function () {
		this.mounted = true;
		this.init();
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getValue: 'search/state2/getValue',
			brandsForCategory: 'shared/brands/getByCategory',
			brandsPopular: 'shared/brands/popular',
			brandsAll: 'shared/brands/getAll',
			suggests: 'shared/brands/suggests',
			getByIndex: 'search/state2/getByIndex',
			getCountryOrigin: 'search/state2/getCountryOrigin0',
			brandData: 'shared/brands/brand'
		}),
		country() {
			return this.getCountryOrigin[this.countryIndex] && !this.exclude ? this.getCountryOrigin[this.countryIndex].id : 0;
		},
		countryRows() {
			let rows = [];
			this.getCountryOrigin.forEach((item, index) => {
				if (!item.not) {
					rows.push(index);
				}
			});
			if (!rows.length) {
				let index = this.getCountryOrigin.length;
				this.addByIndex({
					name: 'country.origin',
					index: index,
					value: {
						not: true
					}
				});
				rows = [index];
			}
			return rows;
		},
		countryIndex() {
			return this.countryRows[0];
		},
		postfix: function () {
			return `brand-${this.blockIndex}${this.exclude ? '-not' : ''}`;
		},
		category: function () {
			return this.getValue('categories.main.id');
		},
		brand: function () {
			let value = this.groupValue;
			return value.brand || 0;
		},
		groupValue: function () {
			return this.getByIndex({
				name: 'series',
				index: this.indices[0]
			}) || {};
		},
		optionsRaw() {
			return Object.values(this.brandsForCategory(this.category) || {});
		},
		optionsSearched() {
			if (this.mounted) {
				const idsSearched = [...new Set(getFromSearchHistory('brand'))];
				const options = this.optionsRaw;
				return idsSearched.reduce((result, item) => {
					const optionSearched = options.find(({value} = {}) => Number(value) === Number(item));
					if (optionSearched) {
						result.push(Object.assign({}, optionSearched));
					}
					return result;
				}, []).map(item => {
					return {
						value: item.value,
						label: item.name
					};
				});
			} else {
				return [];
			}
		},
		optionsPopular() {
			const category = this.category || 0;
			const popularIds = this.brandsPopular(category);
			if (Array.isArray(popularIds)) {
				const popularIdsHead = category === 1 || !category ? popularIds.slice(0, 20) : popularIds.slice(0, 10);
				return popularIdsHead
					.reduce((result, item) => {
						let {[item]: element} = this.brandsAll;
						if (element) {
							result.push(element);
						}
						return result;
					}, [])
					.sort((a, b) => a.name.localeCompare(b.name, 'en'))
					.map(item => {
						return {
							value: item.value,
							label: item.name
						};
					});
			} else {
				return [];
			}
		},
		optionsAll() {
			return Object.values(this.brandsForCategory(this.category) || {})
				.filter(item => {
					if (this.country) {
						return item.country === this.country;
					} else {
						return true;
					}
				})
				.map(item => {
					return {
						value: item.value,
						label: item.name
					};
				});
		},
		optionsForCategory() {
			let options = [];
			if (this.optionsSearched.length && !this.brand && !this.country) {
				options.push({
					options: this.optionsSearched,
					label: this.$t('Просмотренные')
				});
			}
			if (this.optionsPopular.length && !this.brand && !this.country) {
				options.push({
					options: this.optionsPopular,
					label: this.$t('ТОП марки')
				});
			}
			if (this.optionsAll.length) {
				if (this.brand) {
					let {value, name: label} = this.brandsAll[this.brand];
					options.push({
						options: [{
							label,
							value
						}],
						label: ''
					});
				}
				options.push({
					options: this.optionsAll,
					label: this.brand || this.country ? '' : this.$t('Все марки')
				});
			}
			return options;
		},
		suggestsList() {
			return [{
				options: this.suggests
					.filter(item => {
						if (this.country) {
							return this.brandData(item.value).country === this.country;
						} else {
							return true;
						}
					})
					.map(item => {
						return {
							value: item.value,
							label: item.name
						};
					})
			}];
		},
		options() {
			return (this.mode === 'list' || !this.input_) ? this.optionsForCategory : this.suggestsList;
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	}
};
