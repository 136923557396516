export default {
	messages: {
		ru: {
			'Расход топлива, л. / 100 км': 'Расход топлива, л. / 100 км',
			'От': 'От',
			'До': 'До',
			'Город': 'Город',
			'Шоссе': 'Шоссе',
			'Смешанный': 'Смешанный',
			'Запас хода (км)': 'Запас хода (км)',
			'Емкость аккумулятора (кВт⋅ч)': 'Емкость аккумулятора (кВт⋅ч)',
		},
		uk: {
			'Расход топлива, л. / 100 км': 'Витрата палива, л. / 100 км',
			'От': 'Від',
			'До': 'До',
			'Город': 'Місто',
			'Шоссе': 'Шосе',
			'Смешанный': 'Змішаний',
			'Запас хода (км)': 'Запас ходу (км)',
			'Емкость аккумулятора (кВт⋅ч)': 'Ємність аккумулятору (кВт⋅г)',
		},
	}
};
