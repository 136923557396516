import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CountryImportUSA',
  i18n: require('./i18n').default,
  computed: {
    ...mapGetters({
      getCountryImportUSA: 'search/state2/getCountryImportUSA',
    }),
    checked: {
      get() {
        return this.getCountryImportUSA;
      },
      set(value) {
        let label = 'country_import_usa_all';
        if (Number(value) === 1) {
          label = 'country_import_usa_hide';
        } else if (Number(value) === 0) {
          label = 'country_import_usa_show';
        }
        this.gaEvent('New_Advanced_Search', label, 'USA_auto_hide');
        this.setCountryImportUSA(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setCountryImportUSA: 'search/state2/setCountryImportUSA',
    }),
  },
};
