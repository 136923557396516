import { mapGetters, mapActions} from 'vuex';

export default {
	name: 'Advertisement',
	i18n: require('./i18n').default,
	data(){
		return {
			showWrongParametersPopup: false,
		}
	},
	components: {
		'popup': () => import('../../../Common/Popup.vue'),
	},
	computed: {
		...mapGetters({
			count: 'search/result/count',
			identifier: 'search/advertisement/getIdentifier'
		}),
		carsIsNotFound(){
			return {
				count: Boolean(Number(this.count) === 0),
				identifier: this.identifier,
			}
		}
	},
	watch:{
		carsIsNotFound:
			{
				handler (){
					if( Number(this.count) === 0 && this.identifier && !this.showWrongParametersPopup) {
						this.showWrongParametersPopup = true;
					}
				},
				deep: true
			}
	},
	methods: {
		...mapActions('search/advertisement', {
			updateIdentifier: 'updateIdentifier'
		}),
		handleIdentifier(payload) {
			this.updateIdentifier(payload);
		},
		closePopup(){
			this.showWrongParametersPopup = false;
		}
	}
};
