export default {
	name: 'AdvertisementExchanges',
	components: {
		'vue-payment': require('./Payment/index.vue').default,
		'vue-category': require('./Category/index.vue').default,
		'vue-body': require('./Body/index.vue').default,
		'vue-brand': require('./Brand/index.vue').default,
		'vue-model': require('./Model/index.vue').default,
	}
};
