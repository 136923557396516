import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
	init({store}) {
		return store.dispatch('shared/locations/fetchCenters');
	},
	name: 'Region',
	i18n: require('./i18n').default,
	props: ['index'],
	data: function () {
		return {
			mode: 'list',
			isOptionsHidden: true,
			suggesting: '',
			selectedName: '',
			resetInput: false,
			placeholder: `${this.$t('выберите регион')}`
		};
	},
	components: {
		'vue-autocomplete': require('../../../../Autocomplete/index.vue').default
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getByIndex: 'search/state2/getByIndex',
			regions: 'shared/locations/l10n',
			suggests: 'shared/locations/suggestsCity',
			city_: 'shared/cities/city'
		}),
		postfix: function () {
			return `region-${this.index}`;
		},
		value: function () {
			return this.getByIndex({
				name: 'locations',
				index: this.index
			}) || {};
		},
		region: {
			get: function () {
				return Number(this.value.region) || 0;
			},
			set: function (region) {
				region = Number(region);
				if (region !== this.region) {
					let value = {
						region
					};
					if (!region) {
						value = {};
					}
					this.addByIndex({
						name: 'locations',
						index: this.index,
						value
					});
				}
			}
		},
		city: {
			get: function () {
				return Number(this.value.city) || 0;
			},
			set: function (city) {
				city = Number(city);
				let value = {
					region: this.region,
					city
				};
				if (!city) {
					value = {
						region: this.region
					};
				}
				this.addByIndex({
					name: 'locations',
					index: this.index,
					value
				});
			}
		},
		optionsList: function () {
			let options = Object.values(this.regions)
				.map(item => {
					return {
						value: item.value,
						label: item.name
					};
				})
				.sort((a, b) => a.label.localeCompare(b.label, 'en'));
			return [{
				options: [...options.filter(o => o.value === 10), ...options.filter(o => o.value !== 10)]
			}];
		},
		suggestsList() {
			return [{
				options:
					this.suggests.map(item => {
						return {
							value: item.value,
							label: item.name
						};
					})
			}];
		},
		options() {
			return this.mode === 'list' ? this.optionsList : this.suggestsList;
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/locations/fetchCenters',
			suggest: 'shared/locations/suggestCity'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		init() {
			this.fetch();
		},
		selectAutocomplite(value) {
			value = Number(value);
			if (!value) {
				this.region = 0;
				this.city = 0;
			} else if (this.mode === 'list') {
				this.region = value;
				this.city = 0;
			} else {
				let city = this.city_(value);
				this.region = city.state;
				this.city = city.value;
			}
			this.mode = 'list';
		},
		inputAutocomplite(value) {
			if (value) {
				this.mode = 'autocomplete';
				this.suggest(value);
			} else {
				this.mode = 'list';
			}
		},
		escAutocomplite() {
			this.mode = 'list';
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	}
};
