import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Gearbox',
	i18n: require('./i18n').default,
	init({store}) {
		const category = store.getters['search/state2/getCategory'] || 1;
		return store.dispatch('shared/gearboxes/fetch', {category});
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getGearbox: 'search/state2/getGearbox',
			getCategory: 'search/state2/getCategory',
			gearboxes: 'shared/gearboxes/gearboxes',
		}),
		checkboxes() {
			return this.gearboxes({category: this.category}).map((gearbox) => {
				gearbox.checked = this.getGearbox.indexOf(gearbox.value) >= 0;
				return gearbox;
			});
		},
		category: function () {
			return this.getCategory || 1;
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/gearboxes/fetch',
			setGearbox: 'search/state2/setGearbox',
		}),
		toggleChecked(checkbox) {
			this.setGearbox({value: checkbox.value, set: checkbox.checked});
		},
		init() {
			this.fetch({category: this.category});
		}
	},
	mounted() {
		this.init();
	}
};
