import {mapGetters, mapActions} from 'vuex';

export default {
	name: 'EquipmentType',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getEquipmentType: 'search/state2/getEquipmentType',
			getCategory: 'search/state2/getCategory',
			bodiesByCategory: 'shared/bodies/bodiesByCategory',
			langId: 'lang/id'
		}),
		options() {
			return Object.values(this.bodiesByCategory(this.category, 0))
				.sort((a, b) => a.name > b.name ? 1 : -1);
		},
		category: function () {
			return this.getCategory || 0;
		},
		selected: {
			get: function () {
				return this.getEquipmentType;
			},
			set: function (value) {
				this.setEquipmentType(value);
			}
		}
	},
	methods: {
		...mapActions({
			setEquipmentType: 'search/state2/setEquipmentType'
		})
	},
};
