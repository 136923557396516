const KEY = 'searchHistorySeries';

export default function (type) {
	let searchHistory;
	try {
		searchHistory = JSON.parse(localStorage.getItem(KEY));
	} catch (error) {
		// console.error(error);
	}

	searchHistory = Array.isArray(searchHistory) ? searchHistory : [];
	return searchHistory
		.reduce((result, item = {}) => {
			let {series} = item;
			series = Array.isArray(series) ? series : [];
			series.forEach((item = {}) => {
				const {[type]: value} = item;
				if (value) result.push(value);
			});
			return result;
		}, [])
		.map(Number);
}
