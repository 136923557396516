import translate from "./i18n";

export default {
    name: "SelectFieldWrapper",
    props: {
        selectId: {
            type: [String, Number],
            required: true
        },
        selectBackgroundId: {
            type: [String, Number],
            required: true
        },
        selectName: {
            type: [String, Number],
            required: true
        },
        selectBackText: {
            type: String,
        },
        selectText: {
            type: String,
        },
        selectedItemNames: {
            type: String,
            required: true
        },
        selectPlaceholderText: {
            type: String,
            required: true
        },
        hasPhoto: {
            type: Boolean,
            default: false
        },
        hasOptions: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isOpen: 0
        }
    },
    methods: {
        close() {
            this.isOpen = 0;
            this.$emit('select:close');
        },
        toggle(event) {
            if(!this.hasOptions) return;
            this.isOpen = Number(event.target.value)
        },
        focusin() {
            this.$emit('focusin')
        } ,
        focusout() {
            this.$emit('focusout')
        }

    },
    i18n: {
        ...translate
    }
}
