import {mapGetters} from 'vuex';

export default {
  name: 'BrandModel',
  components: {
    Row: () => import('../SeriesRow/index.vue')
  },
  computed: {
    ...mapGetters({
      series: 'cabinet/advertisements/filter/series'
    })
  }
};
