import { mapActions, mapGetters } from 'vuex';

export default {
	data() {
		return {
			opened: false
		};
	},
	name: 'Row',
	props: ['title', 'type'],
	computed: {
		...mapGetters({
			options: 'shared/options/options',
			category: 'search/state2/getCategory',
			getCategory: 'search/state2/getCategory',
			getOptions: 'search/state2/getOptions'
		}),
		category() {
			return this.getCategory || 1;
		},
		checkboxes() {
			return this
				.options({category: this.category, type: this.type})
				.map((option) => {
					option.checked = this.getOptions.indexOf(option.value) >= 0;
					return option;
				});
		}
	},
	methods: {
		...mapActions({
			setOptions: 'search/state2/setOptions'
		}),
		toggleChecked(checkbox) {
			this.setOptions({value: checkbox.value, set: checkbox.checked});
		}
	}
};
