export default {
	data: function () {
		return {
			add_: false
		};
	},
	name: 'Regions',
	i18n: require('./i18n').default,
	components: {
		'vue-mobile': require('./Mobile/index.vue').default,
		'vue-desktop': require('./Desktop/index.vue').default,
	},
	methods: {
		add() {
			this.add_ = !this.add_;
		}
	}
};
