export default {
	messages: {
		ru: {
			'Цвет': 'Цвет',
			'Выберите цвет': 'Выберите цвет',
			'Металлик': 'Металлик'
		},
		uk: {
			'Цвет': 'Колір',
			'Выберите цвет': 'Оберіть колір',
			'Металлик': 'Металік'
		},
	}
};
