export default {
	messages: {
		ru: {
			'Выберите модель': 'Выберите модель',
			'Сначала выберите марку': 'Сначала выберите марку',
			'Просмотренные': 'Просмотренные',
			'Популярные': 'Популярные',
			'Все модели': 'Все модели'
		},
		uk: {
			'Выберите модель': 'Оберіть модель',
			'Сначала выберите марку': 'Спочатку виберіть марку',
			'Просмотренные': 'Переглянуті',
			'Популярные': 'Популярні',
			'Все модели': 'Всі моделі'
		},
	}
};
