import { mapActions, mapGetters, mapMutations } from 'vuex';
import paramHelper from '../../helpers/param';

const { get } = require('../../helpers/cookie');

export default {
  name: 'AdvancedSearch',
  i18n: require('./i18n').default,
  data() {
    return {
      toggleFixedShadow: false,
      IOW: undefined,
      IOWEntry: undefined,
      test: false,
    };
  },
  components: {
    'vue-checks': require('./Checks/index.vue').default,
    'vue-category': require('./Category/index.vue').default,
    'vue-equipment-type': require('./EquipmentType/index.vue').default,
    'vue-body': require('./Body/index.vue').default,
    'vue-country-origin': require('./CountryOrigin/index.vue').default,
    'vue-brand-model': require('./BrandModel/index.vue').default,
    'vue-exclude': require('./Exclude/index.vue').default,
    'vue-price': require('./Price/index.vue').default,
    'vue-regions': require('./Regions/index.vue').default,
    'vue-state': require('./State/index.vue').default,
    'vue-fuel': require('./Fuel/index.vue').default,
    'vue-gearbox': require('./Gearbox/index.vue').default,
    'vue-drive': require('./Drive/index.vue').default,
    'vue-eco': require('./EcoStandart/index.vue').default,
    'vue-fuel-consumption': require('./FuelConsumption/index.vue').default,
    'vue-engine': require('./Engine/index.vue').default,
    'vue-power': require('./Power/index.vue').default,
    'vue-mileage': require('./Mileage/index.vue').default,
    'vue-carrying': require('./Carrying/index.vue').default,
    'vue-axle': require('./Axle/index.vue').default,
    'vue-wheel': require('./Wheel/index.vue').default,
    'vue-doors': require('./Doors/index.vue').default,
    'vue-seats': require('./Seats/index.vue').default,
    'vue-color': require('./Color/index.vue').default,
    'vue-options': require('./Options/index.vue').default,
    'vue-result-block': require('./ResultBlock/index.vue').default,
    'vue-result': require('./Result/index.vue').default,
    'vue-labels': require('./Labels/index.vue').default,
    'vue-search-history': require('./SearchHistory/index.vue').default,
    'vue-subscribe': require('./Subscribe/index.vue').default,
  },
  metaInfo() {
    const _seo = seo[this.langId];
    return {
      title: _seo.title,
      meta: [
        { name: 'title', content: _seo.title },
        { name: 'description', content: _seo.description },
        { name: 'keywords', content: _seo.keywords },
        { property: 'og:title', content: `${_seo.title}.` },
        { property: 'og:description', content: `${_seo.description}.` },
        { property: 'og:image', content: 'https://img.auto.ria.com/images/languages/ru/logo.png' },
        { name: 'ROBOTS', content: this.robotsContent() },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getSearchQuery: 'search/state2/getSearchQuery',
      getValue: 'search/state2/getValue',
      langId: 'lang/id',
      isCreateNewSubscribe: 'search/state2/isCreateNewSubscribe',
      isEditSubscribe: 'search/state2/isEditSubscribe',
    }),
    category() {
      return Number(this.getValue('categories.main.id'));
    },
    shouldShowEquipmentType() {
      return this.test && this.category === 10;
    },
    shouldShowBodies() {
      return this.category;
    },
    shouldShowDrives() {
      return [3, 5, 8, 9].indexOf(this.category) === -1;
    },
    shouldShowGearboxes() {
      return [3, 5, 8, 9].indexOf(this.category) === -1;
    },
    shouldShowFuel() {
      return [5, 9].indexOf(this.category) === -1;
    },
    shouldShowSeats() {
      return [2, 5].indexOf(this.category) === -1;
    },
    shouldShowDoors() {
      return [2, 3, 5, 9].indexOf(this.category) === -1;
    },
    shouldFuelConsumption() {
      return [3, 5, 9].indexOf(this.category) === -1;
    },
    shouldPower() {
      return [5, 8, 9].indexOf(this.category) === -1;
    },
    shouldEngine() {
      return [5, 8, 9].indexOf(this.category) === -1;
    },
    shouldShowCarrying() {
      return [4, 5, 6, 7, 10].indexOf(this.category) >= 0;
    },
    shouldShowAxle() {
      return [4, 5, 6, 10].indexOf(this.category) >= 0;
    },
    shouldShowWheel() {
      return [4, 5, 6, 10].indexOf(this.category) >= 0;
    },
  },
  watch: {
    async getSearchQuery(query) {
      this.$router.push({ query });
    },
  },
  methods: {
    ...mapMutations({
      setMany: 'search/state2/setMany',
    }),
    ...mapActions({
      queryConvert: 'search/state2/queryConvert',
    }),
    fillParams() {
      const { query } = this.$route;
      this.setMany(paramHelper.state(query));
    },
    robotsContent() {
      const isQueryStringPresent = Boolean(Object.keys(this.$route.query).length) || false;
      return isQueryStringPresent ? 'NOINDEX, NOFOLLOW' : 'INDEX, FOLLOW';
    },
  },
  mounted() {
    this.queryConvert(this.$route.query);
    try {
      this.IOW = require('../../helpers/IntersectionObserver_screen__01');
      this.IOW.add(this.$refs.EmptyDiv, (entry = {}) => {
        this.IOWEntry = entry;
        this.toggleFixedShadow = entry.isIntersecting;
      });
    } catch (error) {
    }
    window.addEventListener('popstate', this.fillParams);
    if (get('test_new_features') === '7388') {
      this.test = true;
    }
  },
  beforeDestroy() {
    if (this.IOWEntry) {
      this.IOW.del(this.IOWEntry.target);
    }
    window.removeEventListener('popstate', this.fillParams);
  },
};

let seo = {
  2: {
    title: 'Автопоиск машин в Украине. Удобный поиск авто в любом регионе страны',
    description: 'Гнучкий пошук вживаного авто за вашими параметрами в будь-якому місті України на AUTO.RIA',
    keywords: 'поиск, поиск авто, авто, auto, продажа авто, автомобили, автосалоны, авторынок, базар авто, каталог автомобилей',
  },
  4: {
    title: 'Пошук автомобілів в Україні. Зручний пошук авто в будь-якому регіоні країни',
    description: 'Гнучкий пошук вживаного авто за вашими параметрами в будь-якому місті України на AUTO.RIA',
    keywords: 'пошук, пошук авто, авто, auto, продаж авто, автомобілі, автосалони, авторинок, базар авто, каталог автомобілів',
  },
};
