/* eslint-disable global-require,import/no-extraneous-dependencies,no-restricted-globals */
import { mapActions, mapGetters } from 'vuex';
import _laxios from '../../../store/_laxios';
import { agroRia as agroRiaOrigin } from '../../../../constants/origins';

export default {
  name: 'Result',
  i18n: require('./i18n').default,
  props: ['toggleFixedShadow'],
  computed: {
    ...mapGetters({
      getSearchQuery: 'search/state2/getSearchQuery',
      count: 'search/result/count',
      gdrpHeight: 'shared/gdrp/height',
    }),
    ...mapGetters({
      getAdvertisementId: 'search/advertisement/getIdentifier',
    }),
    countConverted() {
      return isNaN(Number(this.count)) ? '' : Number(this.count).toLocaleString('ru-RU');
    },
  },
  watch: {
    getSearchQuery() {
      this.init();
    },
  },
  methods: {
    ...mapActions({
      getSearchData: 'search/state2/getSearchData',
      search: 'search/result/search',
    }),
    async gotoSearch() {
      this.gaEvent('advanced-search_view', 'click_on_button_search', 'advanced-search');
      if (Number(this.getAdvertisementId) && this.count === 1) {
        location.href = `${this.langPrefix}/auto___${this.getAdvertisementId}.html`;
      } else {
        const searchQuery = this.getSearchQuery;
        const langId = this.langId
        // редирект на Агро якщо обрано Сільгосптехніка
        const { urlToRedirect } = await _laxios.dispatchAgroCatalog.request({ data: { ...searchQuery, langId } });

        if (urlToRedirect) {
          location.href = urlToRedirect;
        } else {
          const sq = Object.keys(searchQuery).map((param) => `${param}=${searchQuery[param]}`).join('&');
          const locationHref = `${this.langPrefix}/search/${sq ? `?${sq}` : ''}`;
          location.href = locationHref;
        }
      }
    },
    init() {
      this.search(this.getSearchQuery);
    },
  },
  mounted() {
    this.init();
  },
};
