export default {
	messages: {
		ru: {
			'Конфискат': 'Конфискат',
			'Все': 'Все',
			'Показать': 'Показать',
			'Скрыть': 'Скрыть'
		},
		uk: {
			'Конфискат': 'Конфіскат',
			'Все': 'Всі',
			'Показать': 'Показати',
			'Скрыть': 'Сховати'
		},
	}
};
