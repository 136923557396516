export default {
	messages: {
		ru: {
			'Расширенный поиск б/у авто': 'Расширенный поиск б/у авто',
			'Технические характеристики': 'Технические характеристики',
			'Мои подписки': 'Мои подписки',
			'Новая подписка': 'Новая подписка',
			'Редактирование подписки': 'Редактирование подписки',
		},
		uk: {
			'Расширенный поиск б/у авто': 'Розширений пошук вживаних авто',
			'Технические характеристики': 'Технічні характеристики',
			'Мои подписки': 'Мої підписки',
			'Новая подписка': 'Нова підписка',
			'Редактирование подписки': 'Редагування підписки',
		},
	}
};
