export default {
	messages: {
		ru: {
			'Объем двигателя': 'Объем двигателя',
			'От': 'От',
			'До': 'До'
		},
		uk: {
			'Объем двигателя': "Об'єм двигуна",
			'От': 'Від',
			'До': 'До'
		}
	}
};
