import {mapActions, mapGetters} from 'vuex';

export default {
	init({store}) {
		const category = store.getters['search/state2/getExchangesCategory'];
		if (category) {
			return store.dispatch('shared/brands/fetchByCategory', category);
		}
	},
	name: 'Brand',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			category: 'search/state2/getExchangesCategory',
			brandsForCategory: 'shared/brands/getByCategory',
			getExchangesBrand: 'search/state2/getExchangesBrand',
		}),
		options() {
			return Object.values(this.brandsForCategory(this.category));
		},
		selected: {
			get() {
				return this.getExchangesBrand;
			},
			set(value) {
				this.setExchangesBrand(value);
			}
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetchBrands: 'shared/brands/fetchByCategory',
			setExchangesBrand: 'search/state2/setExchangesBrand'
		}),
		init() {
			if (this.category) {
				this.fetchBrands(this.category);
			}
		}
	},
	mounted() {
		this.init();
	}
};
