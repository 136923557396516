export default {
	messages: {
		ru: {
			'КПП': 'КПП'
		},
		uk: {
			'КПП': 'КПП'
		},
	}
};
