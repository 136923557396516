export default {
  messages: {
    ru: {
      'Гос. номер': 'Гос. номер',
      'Все': 'Все',
      'Б/у': 'Б/у',
      'Новые': 'Новые',
      'Под пригон': 'Под пригон',
      'Вперше': 'Впервые',
      'Електрокари': 'Электрокары',
    },
    uk: {
      'Гос. номер': 'Держ. номер',
      'Все': 'Всі',
      'Б/у': 'Вживані',
      'Новые': 'Нові',
      'Под пригон': 'Під пригон',
      'Вперше': 'Вперше',
      'Електрокари': 'Електрокари',
    },
  },
};
