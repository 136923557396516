export default {
	messages: {
		ru: {
			'Состояние': 'Состояние'
		},
		uk: {
			'Состояние': 'Стан'
		},
	}
};
