import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Sort',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getSort: 'search/state2/getSort'
		}),
		sort: {
			get: function () {
				return this.getSort;
			},
			set: function (value) {
				this.setSort(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setSort: 'search/state2/setSort'
		}),
	}
};
