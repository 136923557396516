export default {
  messages: {
    ru: {
      'Показать авто из США': 'Показать авто из США',
      'Скрыть авто из США': 'Скрыть авто из США',
      'Как новое': 'Как новое',
      'Профессионально исправленные следы использования': 'Профессионально исправленные следы использования',
      'Неисправленные следы использования': 'Неисправленные следы использования',
      'Полностью неповрежденное': 'Полностью неповрежденное',
      'Профессионально отремонтированные повреждения': 'Профессионально отремонтированные повреждения',
      'Не отремонтированные повреждения': 'Не отремонтированные повреждения',
      'Не на ходу / На запчасти': 'Не на ходу / На запчасти',
      Изменить: 'Изменить',
      Удалить: 'Удалить',
      'Возможен обмен на автомобиль': 'Возможен обмен на автомобиль',
      'Возможен обмен на недвижимость': 'Возможен обмен на недвижимость',
      'Моя доплата': 'Моя доплата',
      'Хочу доплату': 'Хочу доплату',
      'Равноценный обмен': 'Равноценный обмен',
      'Авто в Украине': 'Авто в Украине',
      'Авто не в Украине': 'Авто не в Украине',
      'Нерастаможенные авто': 'Нерастаможенные авто',
      Растаможенные: 'Растаможенные',
      'Не в кредит': 'Не в кредит',
      'Только кредитные': 'Только кредитные',
      'Не конфискованные': 'Не конфискованные',
      Конфискованные: 'Конфискованные',
      Металлик: 'Металлик',
      Битые: 'Битые',
      Небитые: 'Небитые',
      'Скрыть не на ходу': 'Скрыть не на ходу',
      'Только не на ходу': 'Только не на ходу',
      'Частное лицо': 'Частное лицо',
      Компания: 'Компания',
      'От дешевых к дорогим': 'От дешевых к дорогим',
      'От дорогих к дешевым': 'От дорогих к дешевым',
      'Дата добавления': 'Дата добавления',
      'Год выпуска, по возрастанию': 'Год выпуска, по возрастанию',
      'Год выпуска, по убыванию': 'Год выпуска, по убыванию',
      'Пробег, по возрастанию': 'Пробег, по возрастанию',
      'Пробег, по убыванию': 'Пробег, по убыванию',
      За: 'За',
      сегодня: 'сегодня',
      сутки: 'сутки',
      дня: 'дня',
      неделю: 'неделю',
      месяц: 'месяц | месяц | месяца | месяцев',
      час: 'час | час | часа | часов',
      'Скрыть проданные': 'Скрыть проданные',
      'Только проданные': 'Только проданные',
      По: 'По',
      'Только с фото': 'Только с фото',
      'Только с видео': 'Только с видео',
      'Возможна оплата частями': 'Можлива оплата частинами',
      больше: 'больше',
      от: 'от',
      до: 'до',
      'Расход топлива': 'Расход топлива',
      'Объем двигателя': 'Объем двигателя',
      Мощность: 'Мощность',
      Пробег: 'Пробег',
      'Без пробігу': 'Без пробега',
      Грузоподъемность: 'Грузоподъемность',
      'Количество дверей': 'Количество дверей',
      'Количество мест': 'Количество мест',
      'л.с.': 'л.с.',
      'тыс. км': 'тыс. км',
      'гг.': 'гг.',
      'г.': 'г.',
      год: 'год',
      'Возможен торг': 'Возможен торг',
      'Перевірки VIN+, VIN MAX, CARFAX': 'Проверки VIN+, VIN MAX, CARFAX',
      'Технічно на СТО': 'Технически на СТО',
      'Виїзна перевірка AUTO.RIA': 'Выездная проверка AUTO.RIA',
    },
    uk: {
      'Показать авто из США': 'Показати авто із США',
      'Скрыть авто из США': 'Сховати авто із США',
      'Как новое': 'Як нове',
      'Профессионально исправленные следы использования': 'Професійно виправлені сліди використання',
      'Неисправленные следы использования': 'Невиправлені сліди використання',
      'Полностью неповрежденное': 'Повністю непошкоджене',
      'Профессионально отремонтированные повреждения': 'Професійно відремонтовані пошкодження',
      'Не отремонтированные повреждения': 'Не відремонтовані пошкодження',
      'Не на ходу / На запчасти': 'Не на ходу / На запчастини',
      Изменить: 'Змінити',
      Удалить: 'Видалити',
      'Возможен обмен на автомобиль': 'Можливий обмін на автомобіль',
      'Возможен обмен на недвижимость': 'Можливий обмін на нерухомість',
      'Моя доплата': 'Моя доплата',
      'Хочу доплату': 'Бажаю доплату',
      'Равноценный обмен': 'Рівноцінний обмін',
      'Авто в Украине': 'Авто в Україні',
      'Авто не в Украине': 'Авто не в Україні',
      'Нерастаможенные авто': 'Нерозмитнені авто',
      Растаможенные: 'Розмитнені',
      'Не в кредит': 'Не в кредит',
      'Только кредитные': 'Тільки кредитні',
      'Не конфискованные': 'Не конфісковані',
      Конфискованные: 'Конфісковані',
      Металлик: 'Металік',
      Битые: 'Биті',
      Небитые: 'Небиті',
      'Скрыть не на ходу': 'Приховати не на ходу',
      'Только не на ходу': 'Тільки не на ходу',
      'Частное лицо': 'Приватна особа',
      Компания: 'Компанія',
      'От дешевых к дорогим': 'Від дешевих до дорогих',
      'От дорогих к дешевым': 'Від дорогих до дешевих',
      'Дата добавления': 'Дата додавання',
      'Год выпуска, по возрастанию': 'Рік випуску, за зростанням',
      'Год выпуска, по убыванию': 'Рік випуску, за спаданням',
      'Пробег, по возрастанию': 'Пробіг, за зростанням',
      'Пробег, по убыванию': 'Пробіг, за спаданням',
      За: 'За',
      сегодня: 'сьогодні',
      сутки: 'добу',
      дня: 'дні',
      неделю: 'тиждень',
      месяц: 'місяць | місяць | місяці | місяців',
      час: 'годину | годину | години | годин',
      'Скрыть проданные': 'Приховати продані',
      'Только проданные': 'Тільки продані',
      По: 'По',
      'Только с фото': 'Тільки з фото',
      'Только с видео': 'Тільки з відео',
      'Возможна оплата частями': 'Можлива оплата частинами',
      больше: 'більше',
      от: 'від',
      до: 'до',
      'Расход топлива': 'Витрата палива',
      'Объем двигателя': "Об'єм двигуна",
      Мощность: 'Потужність',
      Пробег: 'Пробіг',
      'Без пробігу': 'Без пробігу',
      Грузоподъемность: 'Вантажопідйомність',
      'Количество дверей': 'Кількість дверей',
      'Количество мест': 'Кількість місць',
      'л.с.': 'к.с.',
      'тыс. км': 'тис. км',
      'гг.': 'рр.',
      'г.': 'р.',
      год: 'рік',
      'Возможен торг': 'Можлиивий торг',
      'Перевірки VIN+, VIN MAX, CARFAX': 'Перевірки VIN+, VIN MAX, CARFAX',
      'Технічно на СТО': 'Технічно на СТО',
      'Виїзна перевірка AUTO.RIA': 'Виїзна перевірка AUTO.RIA',
    },
  },
};
