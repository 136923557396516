export default {
	messages: {
		ru: {
			'Не задано': 'Не задано',
			'Лакокрасочное покрытие': 'Лакокрасочное покрытие',
			'Как новое': 'Как новое',
			'Оригинальное лакокрасочное покрытие, без следов пользования и подкрасов': 'Оригинальное лакокрасочное покрытие, без следов пользования и подкрасов',
			'Профессионально исправленные следы использования': 'Профессионально исправленные следы использования',
			'Например, повторное лакирование, мелкий ремонт, рихтовка небольших вмятин': 'Например, повторное лакирование, мелкий ремонт, рихтовка небольших вмятин',
			'Неисправленные следы использования': 'Неисправленные следы использования',
			'Нормальный износ, напр., небольшие вмятины, царапины лакокрасочного покрытия, сколы': 'Нормальный износ, напр., небольшие вмятины, царапины лакокрасочного покрытия, сколы',
		},
		uk: {
			'Не задано': 'Не задано',
			'Лакокрасочное покрытие': 'Лакофарбове покриття',
			'Как новое': 'Як нове',
			'Оригинальное лакокрасочное покрытие, без следов пользования и подкрасов': 'Оригінальне лакофарбове покриття, без слідів користування та підфарбовувань',
			'Профессионально исправленные следы использования': 'Професійно виправлені сліди використання',
			'Например, повторное лакирование, мелкий ремонт, рихтовка небольших вмятин': 'Наприклад, повторне лакування, дрібний ремонт, рихтування невеликих вм\'ятин',
			'Неисправленные следы использования': 'Невиправлені сліди використання',
			'Нормальный износ, напр., небольшие вмятины, царапины лакокрасочного покрытия, сколы': 'Нормальне зношення, наприклад, невеликі вм\'ятини, подряпини лакофарбового покриття, сколи',
		},
	}
};
