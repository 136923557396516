import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Seats',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getSeats: 'search/state2/getSeats'
		}),
		gte: {
			get() {
				return this.getSeats.gte;
			},
			set(value) {
				let payload = this.getSeats;
				payload.gte = value;
				this.setSeats(payload);
			}
		},
		lte: {
			get() {
				return this.getSeats.lte;
			},
			set(value) {
				let payload = this.getSeats;
				payload.lte = value;
				this.setSeats(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setSeats: 'search/state2/setSeats'
		})
	}
};
