import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  i18n: require('./i18n').default,
  init({ store }) {
    return store.dispatch('shared/cities/fetch');
  },
  components: {
    'vue-autocomplete': require('../../../Autocomplete/index.vue').default,
  },
  data() {
    return {
      mode: 'list',
      isOptionsHidden: true,
      suggesting: '',
      selectedName: '',
      resetInput: false,
      placeholder: this.$t('Я ищу город ...'),
    };
  },
  watch: {
    langId() {
      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      cities: 'shared/cities/l10n',
      langId: 'lang/id',
      suggestsCity: 'shared/locations/suggestsCity',
      city: 'shared/cities/city',
      getValue: 'search/state2/getValue',
    }),
    values() {
      return this.getValue({
        name: 'locations',
      }).map((item) => (item || {}));
    },
    optionsCityList() {
      const cities = Object.values(this.cities).sort((a, b) => a.name.localeCompare(b.name, 'en'));
      return [{ options: cities.map(({ value, name: label } = {}) => ({ value, label })) }];
    },
    suggestsCityList() {
      return [{
        options: Array.isArray(this.suggestsCity)
          ? this.suggestsCity.map(({ value, name: label } = {}) => ({ value, label }))
          : [],
      }];
    },
    optionsList() {
      return this.mode === 'list' ? this.optionsCityList : this.suggestsCityList;
    },
  },
  methods: {
    ...mapActions({
      fetch: 'shared/cities/fetch',
      suggestCity: 'shared/locations/suggestCity',
    }),
    ...mapMutations({
      addByIndex: 'search/state2/addByIndex',
    }),
    selectAutocomplite(city) {
      city = Number(city);
      if (city) {
        this.resetInput = !this.resetInput;
        this.mode = 'list';
        const region = Number(this.city(city).state);
        for (const value of this.values) {
          if (value.city === city) {
            return;
          }
        }
        let index = this.values.length;
        for (let i = 0; i < this.values.length; i++) {
          const value = this.values[i];
          // видаляємо радіус при додаванні нового міста
          delete value.distance_from_city_km;
          this.addByIndex({
            name: 'locations',
            index: i,
            value: { ...value },
          });
          if (!value.region) {
            index = i;
            break;
          }
        }
        for (let i = 0; i < this.values.length; i++) {
          const value = this.values[i];
          if (value.region === region && !value.city) {
            index = i;
            break;
          }
        }
        this.addByIndex({
          name: 'locations',
          index,
          value: {
            region,
            city,
          },
        });
      }
    },
    inputAutocomplite(value) {
      if (value) {
        this.mode = 'autocomplete';
        this.suggestCity(value);
      } else {
        this.mode = 'list';
      }
    },
    escAutocomplite() {
      this.mode = 'list';
    },
  },
  mounted() {
    this.fetch();
  },
};
