import {mapGetters, mapMutations} from 'vuex';

export default {
	data() {
		return {
			currentBrand: 0,
		};
	},
	name: 'Row',
	props: ['index'],
	components: {
		Brand: () => import('../SeriesBrand/index.vue'),
		Model: () => import('../SeriesModel/index.vue')
	},
	computed: {
		...mapGetters({
			series: 'cabinet/advertisements/filter/series'
		})
	},
	mounted() {
		this.$root.$on('resetFilter', () => {
			this.currentBrand = 0;
		});
	},
	methods: {
		...mapMutations({
			addSeriesRow: 'cabinet/advertisements/filter/addSeriesRow',
			removeSeriesRow: 'cabinet/advertisements/filter/removeSeriesRow'
		}),
		currentBrandChange: function (data) {
			this.currentBrand = data;
		}
	},
	i18n: {
		messages: {
			uk: {
				'Добавить еще': 'Додати ще',
				Удалить: 'Видалити'
			},
			ru: {
				'Добавить еще': 'Добавить еще',
				Удалить: 'Удалить'
			}
		}
	}
};
