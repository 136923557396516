export default {
	messages: {
		ru: {
			'Не задано': 'Не задано',
			'Техническое состояние': 'Техническое состояние',
			'Полностью неповрежденное': 'Полностью неповрежденное',
			'Повреждения или ранее отремонтированные повреждения отсутствуют': 'Повреждения или ранее отремонтированные повреждения отсутствуют',

			'Профессионально отремонтированные повреждения': 'Профессионально отремонтированные повреждения',
			'Повреждения устранены, не требует ремонта': 'Повреждения устранены, не требует ремонта',

			'Не отремонтированные повреждения': 'Не отремонтированные повреждения',
			'Например, после ДТП, следы града, повреждения кузова, неисправность рулевого управления, коробки передач, осей и т.д.': 'Например, после ДТП, следы града, повреждения кузова, неисправность рулевого управления, коробки передач, осей и т.д.',

			'Не на ходу / На запчасти': 'Не на ходу / На запчасти',
			'Из-за ДТП, пожара, неисправностей двигателя и т.д.': 'Из-за ДТП, пожара, неисправностей двигателя и т.д.'
		},
		uk: {
			'Не задано': 'Не задано',
			'Техническое состояние': 'Технічний стан',
			'Полностью неповрежденное': 'Повністю непошкоджене',
			'Повреждения или ранее отремонтированные повреждения отсутствуют': 'Пошкодження або раніше відремонтовані пошкодження відсутні',

			'Профессионально отремонтированные повреждения': 'Професійно відремонтовані пошкодження',
			'Повреждения устранены, не требует ремонта': 'Пошкодження усунуті, не потребує ремонту',

			'Не отремонтированные повреждения': 'Не відремонтовані пошкодження',
			'Например, после ДТП, следы града, повреждения кузова, неисправность рулевого управления, коробки передач, осей и т.д.': 'Наприклад, після ДТП, сліди граду, пошкодження кузова, несправність рульового управління, коробки передач, осей і т.д.',

			'Не на ходу / На запчасти': 'Не на ходу / На запчастини',
			'Из-за ДТП, пожара, неисправностей двигателя и т.д.': 'Через ДТП, пожежу, несправності двигуна і т.д.'
		},
	}
};
