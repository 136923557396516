export default {
	messages: {
		ru: {
			'Период подачи': 'Период подачи',
			'Все': 'Все',
			'За': 'За',
			'сегодня': 'сегодня',
			'сутки': 'сутки',
			'дня': 'дня',
			'неделю': 'неделю',
			'месяц': 'месяц | месяц | месяца | месяцев',
			'час': 'час | час | часа | часов'
		},
		uk: {
			'Период подачи': 'Період подачі',
			'Все': 'Всі',
			'За': 'За',
			'сегодня': 'сьогодні',
			'сутки': 'добу',
			'дня': 'дні',
			'неделю': 'тиждень',
			'месяц': 'місяць | місяць | місяці | місяців',
			'час': 'годину | годину | години | годин'
		}
	}
};
