import {mapActions, mapGetters} from 'vuex';

export default {
	data() {
		return {
			currentBrand: 0,
		};
	},
	name: 'Brand',
	props: ['index'],
	mounted() {
		this.$root.$on('resetFilter', () => {
			this.currentBrand = 0;
		});
	},
	computed: {
		...mapGetters({
			getBrands: 'cabinet/advertisements/filter/getBrands',
		}),
		selected: {
			get: function () {
				return this.currentBrand || 0;
			},
			set(value) {
				this.currentBrand = value;
				this.setBrand({value, index: this.index});
				this.$emit('current-brand-change', value);
			}
		},
		options() {
			if (this.getBrands) {
				return Object.values(this.getBrands);
			}
		}
	},
	methods: {
		...mapActions({
			setBrand: 'cabinet/advertisements/filter/brand',
		})
	},
	i18n: {
		messages: {
			uk: {
				Выберите: 'Виберіть'
			},
			ru: {
				Выберите: 'Выберите'
			}
		}
	}
};
