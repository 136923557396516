import { mapGetters, mapMutations } from 'vuex';
import get from 'lodash/get';

export default {
  name: 'Radius',
  i18n: require('./i18n').default,
  computed: {
    ...mapGetters({
      getValue: 'search/state2/getValue',
    }),
    locations() {
      return this.getValue({ name: 'locations' }).map((item) => (item || {}));
    },
    allCitiesIndexes() {
      return this.locations.map((item, idx) => {
        if (item.city) {
          return idx;
        }
      }).filter((o) => o !== undefined);
    },
    singleCityIdx() {
      return this.allCitiesIndexes.length === 1 ? this.allCitiesIndexes[0] : undefined;
    },
    currentRadiusValue() {
      let currentRadius = 0;
      if (this.singleCityIdx !== undefined) {
        currentRadius = get(this.locations, [this.singleCityIdx, 'distance_from_city_km'], 0);
      }
      return currentRadius;
    },
    showRadiusSelect() {
      return this.allCitiesIndexes.length === 1;
    },
    options() {
      return [10, 20, 50, 70, 100];
    },
    selected: {
      get() {
        return this.currentRadiusValue;
      },
      set(value) {
        this.addByIndex({
          name: 'locations',
          index: this.singleCityIdx,
          value: {
            ...get(this.locations, [this.singleCityIdx], {}),
            distance_from_city_km: value,
          },
        });
      },
    },
  },
  methods: {
    ...mapMutations({
      addByIndex: 'search/state2/addByIndex',
    }),
  },
};
