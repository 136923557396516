import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Top',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getTop: 'search/state2/getTop'
		}),
		top: {
			get: function () {
				return this.getTop;
			},
			set: function (value) {
				this.setTop(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setTop: 'search/state2/setTop'
		}),
	},
};
