import { mapGetters, mapMutations } from 'vuex';
import i18n from './i18n';

export default {
  name: 'Mileage',
  i18n,
  data: () => ({
    noMileageCheckbox: false,
  }),
  computed: {
    ...mapGetters({
      langId: 'lang/id',
      getMileage: 'search/state2/getMileage',
    }),
    noMileage: {
      get() {
        return this.noMileageCheckbox;
      },
      set(value) {
        this.updateNoMileage(value);
      },
    },
  },
  watch: {
    'getMileage.lte': function (value) {
      this.noMileageCheckbox = value === 0;
    },
  },
  methods: {
    ...mapMutations({
      setMileage: 'search/state2/setMileage',
    }),
    updateMileage(event, id) {
      const { value } = event.target;
      const payload = {
        ...this.getMileage,
        [id]: value,
      };

      this.setMileage(payload);
    },
    updateNoMileage(value) {
      this.setMileage({
        gte: 0,
        lte: 0,
        canSaveZero: value,
      });
    },
    logClick() {
      this._slonik({
        event_id: 1340,
        click_action: 1,
        block_type: 1,
        previous_url: document.referrer,
      });
    },
  },
};
