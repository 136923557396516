import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
	name: 'CountryImport',
	i18n: require('./i18n').default,
	init({store}) {
		return store.dispatch('shared/countries/fetch');
	},
	computed: {
		...mapGetters({
			getCountryImport: 'search/state2/getCountryImport',
			countries: 'shared/countries/l10n',
			langId: 'lang/id'
		}),
		countriesOptionsList() {
			return Object.values(this.countries)
				.sort((a, b) => a.name.localeCompare(b.name, 'en'));
		},
		selected: {
			get: function () {
				return this.getCountryImport;
			},
			set: function (value) {
				this.setCountryImport(value);
			}
		}
	},
	watch: {
		langId() {
			this.fetchCountries(this.langId);
		}
	},
	methods: {
		...mapActions({
			fetchCountries: 'shared/countries/fetch'
		}),
		...mapMutations({
			setCountryImport: 'search/state2/setCountryImport'
		})
	},
	mounted() {
		this.fetchCountries();
	}
};
