import { mapGetters, mapMutations } from 'vuex';

const groupParams = [
  'price.USD.gte', 'price.USD.lte', 'price.EUR.gte', 'price.EUR.lte', 'price.UAH.gte', 'price.UAH.lte',
  'fuel.consumption.city.gte', 'fuel.consumption.city.lte', 'fuel.consumption.route.gte', 'fuel.consumption.route.lte', 'fuel.consumption.combine.gte', 'fuel.consumption.combine.lte',
  'engine.gte', 'engine.lte',
  'power.hp.gte', 'power.hp.lte', 'power.kW.gte', 'power.kW.lte',
  'mileage.gte', 'mileage.lte',
  'doors.gte', 'doors.lte',
  'seats.gte', 'seats.lte',
  'carrying.gte', 'carrying.lte',
];
const array = ['body.id[i]', 'country.origin.id[i]', 'fuel.id[i]', 'gearbox.id[i]', 'drive.id[i]', 'eco_standart[i]', 'color.type[i]', 'color.id[i]', 'options.id[i]'];

export default {
  name: 'Labels',
  i18n: require('./i18n').default,
  data() {
    return {
      count: 0,
      hoveredLabel: {},
    };
  },
  components: {
    'vue-label': require('./Label/index.vue').default,
  },
  computed: {
    ...mapGetters({
      getState: 'search/state2/getState',

    }),
    labels() {
      const labels = [];
      const hashIndex = {};

      for (const param in this.getState) {
        if (this.getState.hasOwnProperty(param)) {
          if (param === 'post.exchanges.type.id') {
            if (this.getState[param] === 3) {
              labels.push({
                value: 1,
                name: param,
              });
              labels.push({
                value: 2,
                name: param,
              });
            } else {
              labels.push({
                value: this.getState[param],
                name: param,
              });
            }
          } else if (param === 'country.origin') {
            for (let i = 0; i < this.getState[param].length; i++) {
              const { id: value, not } = this.getState[param][i];
              if (value) {
                labels.push({
                  value,
                  name: 'country.origin',
                  index: i,
                  not,
                });
              }
            }
          } else if (param === 'series') {
            for (let i = 0; i < this.getState[param].length; i++) {
              const {
                brand, model, generation, modification, year = {}, not,
              } = this.getState[param][i] || {};
              if (brand) {
                labels.push({
                  value: brand,
                  name: 'brand',
                  index: i,
                  not,
                });
              }
              if (model) {
                labels.push({
                  value: model,
                  name: 'model',
                  index: i,
                  brand, // треба в випадку видалення - щоб і видалити генерації
                  not,
                });
              }
              if (generation) {
                generation.forEach((el) => {
                  labels.push({
                    value: el,
                    name: 'generation',
                    index: i,
                    not,
                  });
                });
              }
              if (modification) {
                modification.forEach((el) => {
                  labels.push({
                    value: el.value,
                    name: 'modification',
                    generationId: el.generationId,
                    index: i,
                    not,
                  });
                });
              }
              if (year.gte || year.lte) {
                labels.push({
                  value: {
                    gte: year.gte,
                    lte: year.lte,
                  },
                  name: 'year',
                  index: i,
                  not,
                });
              }
            }
          } else if (param === 'locations') {
            for (let i = 0; i < this.getState[param].length; i++) {
              const { region, city, distance_from_city_km } = this.getState[param][i] || {};
              if (region) {
                labels.push({
                  value: region,
                  name: 'region',
                  index: i,
                });
              }
              if (city) {
                labels.push({
                  value: city,
                  name: 'city',
                  index: i,
                });
              }
              if (distance_from_city_km) {
                labels.push({
                  value: distance_from_city_km,
                  name: 'distance_from_city_km',
                  index: i,
                });
              }
            }
          } else if (groupParams.indexOf(param) >= 0) {
            const param_ = param.replace(/\.(gte|lte)/, '');
            if (!hashIndex[`${param_}.gte,${param_}.lte`]) {
              labels.push({
                value: {
                  gte: this.getState[`${param_}.gte`],
                  lte: this.getState[`${param_}.lte`],
                },
                name: `${param_}.gte,${param_}.lte`,
                group: true,
              });
              hashIndex[`${param_}.gte,${param_}.lte`] = true;
            }
          } else if (array.indexOf(param) >= 0) {
            for (let i = 0; i < this.getState[param].length; i++) {
              labels.push({
                value: this.getState[param][i],
                name: param,
                array: true,
              });
            }
          } else {
            labels.push({
              value: this.getState[param],
              name: param,
            });
          }
        }
      }

      this.count = 0;
      const excludeStrikeField = ['modification', 'generation'];
      return labels.map((label) => {
        if (this.hoveredLabel.name === label.name) {
          if (label.hasOwnProperty('index') && !excludeStrikeField.includes(this.hoveredLabel.name)) {
            if (this.hoveredLabel.index === label.index) {
              label.isStrikedOut = true;
            }
          } else if (this.hoveredLabel.value === label.value) {
            label.isStrikedOut = true;
          }
        } else if (this.hoveredLabel.name === 'categories.main.id') {
          if (['body.id[i]', 'drive.id[i]', 'gearbox.id[i]', 'fuel.id[i]', 'options.id[i]',
            'seats.gte,seats.lte', 'doors.gte,doors.lte',
            'fuel.consumption.city.gte,fuel.consumption.city.lte',
            'fuel.consumption.route.gte,fuel.consumption.route.lte',
            'fuel.consumption.combine.gte,fuel.consumption.combine.lte',
            'power.hp.gte,power.hp.lte', 'power.kW.gte,power.kW.lte',
            'carrying.gte,carrying.lte', 'engine.gte,engine.lte',
            'axle', 'wheel.id', 'eco_standart[i]', 'color.type[i]',
            'brand', 'model', 'generation', 'modification'].indexOf(label.name) >= 0) {
            label.isStrikedOut = true;
          }
        } else if (this.hoveredLabel.name === 'brand') {
          if ((label.name === 'model' || label.name === 'generation' || label.name === 'modification') && label.index === this.hoveredLabel.index) {
            label.isStrikedOut = true;
          }
        } else if (this.hoveredLabel.name === 'model') {
          if ((label.name === 'generation' || label.name === 'modification') && label.index === this.hoveredLabel.index) {
            label.isStrikedOut = true;
          }
        } else if (this.hoveredLabel.name === 'generation') {
          if (label.name === 'modification' && label.index === this.hoveredLabel.index && this.hoveredLabel.value === label.generationId) {
            label.isStrikedOut = true;
          }
        }
        return label;
      });
    },
  },
  methods: {
    ...mapMutations({
      setMany: 'search/state2/setMany',
    }),
    canDelete(label) {
      this.hoveredLabel = label;
    },
    reset() {
      this.setMany({});
    },
    changeOffsetTop(payload) {
      if (payload.offsetTop) {
        this.count++;
      }
    },
  },
};
