import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Doors',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getDoors: 'search/state2/getDoors'
		}),
		gte: {
			get() {
				return this.getDoors.gte;
			},
			set(value) {
				let payload = this.getDoors;
				payload.gte = value;
				this.setDoors(payload);
			}
		},
		lte: {
			get() {
				return this.getDoors.lte;
			},
			set(value) {
				let payload = this.getDoors;
				payload.lte = value;
				this.setDoors(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setDoors: 'search/state2/setDoors'
		})
	}
};
