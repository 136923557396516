export default {
	messages: {
		ru: {
			'Стоимость': 'Стоимость',
			'От': 'От',
			'До': 'До'
		},
		uk: {
			'Стоимость': 'Вартість',
			'От': 'Від',
			'До': 'До'
		},
	}
};
