export default {
	messages: {
		uk: {
			'Пошук по ID, VIN-коду чи держ. номеру': 'Пошук по ID, VIN-коду чи держ. номеру',
			'Введіть дані': 'Введіть дані',
			'Пропозицій не знайдено': 'Пропозицій не знайдено &#x1F914',
			'Перевірити': 'Перевірити',
			'Перевірте правильність введених данних': 'Перевірте правильність введених данних'
		},
		ru: {
			'Пошук по ID, VIN-коду чи держ. номеру': 'Поиск по ID, VIN-коду или гос. номеру',
			'Введіть дані': 'Введите данные',
			'Пропозицій не знайдено': 'Предложений не найдено &#x1F914',
			'Перевірити': 'Проверить',
			'Перевірте правильність введених данних': 'Провериьте правильность введених данных'
		},
	}
};
