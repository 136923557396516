import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Price',
	i18n: require('./i18n').default,
	components: {
		'vue-exchanges': require('./Exchanges/index.vue').default
	},
	computed: {
		...mapGetters({
			getPrice: 'search/state2/getPrice'
		}),
		gte: {
			get() {
				return this.getPrice.gte;
			},
			set(value) {
				let payload = this.getPrice;
				payload.gte = value;
				this.setPrice(payload);
			}
		},
		lte: {
			get() {
				return this.getPrice.lte;
			},
			set(value) {
				let payload = this.getPrice;
				payload.lte = value;
				this.setPrice(payload);
			}
		},
		currency: {
			get() {
				return this.getPrice.currency;
			},
			set(value) {
				let payload = this.getPrice;
				payload.currency = value;
				this.setPrice(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setPrice: 'search/state2/setPrice',
		})
	}
};
