export default {
	messages: {
		ru: {
			'Ціна з ПДВ': 'Цена с НДС',
			'Возможен торг': 'Возможен торг',
			'Возможен обмен на автомобиль': 'Возможен обмен на автомобиль',
			'При продаже авто юридическим лицам возмещается сумма НДС': 'При продажу авто юридичним особам відшкодовується сума ПДВ',
			'Возможна оплата частями': 'Возможна оплата частями',
		},
		uk: {
			'Ціна з ПДВ': 'Ціна з ПДВ',
			'Возможен торг': 'Можливий торг',
			'Возможен обмен на автомобиль': 'Можливий обмін на автомобіль',
			'При продаже авто юридическим лицам возмещается сумма НДС': 'При продажу авто юридичним особам відшкодовується сума ПДВ',
			'Возможна оплата частями': 'Можлива оплата частинами',
		},
	}
};
