import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
	init({store}) {
		const category = store.getters['search/state2/getExchangesCategory'];
		return store.dispatch('shared/bodies/fetch', {category});
	},
	name: 'Body',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			category: 'search/state2/getExchangesCategory',
			bodiesByCategory: 'shared/bodies/bodiesByCategory',
			getExchangesBody: 'search/state2/getExchangesBody',
		}),
		bodies() {
			return this.bodiesByCategory(this.category);
		},
		options() {
			return this.bodies;
		},
		selected: {
			get() {
				return this.getExchangesBody;
			},
			set(value) {
				this.setExchangesBody(value);
			}
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	},
	methods: {
		...mapMutations({
			setExchangesBody: 'search/state2/setExchangesBody'
		}),
		...mapActions({
			fetchBodies: 'shared/bodies/fetch'
		}),
		init() {
			if (this.category) {
				this.fetchBodies({category: this.category});
			}
		}
	},
	mounted() {
		this.init();
	}
};
