export default {
	messages: {
		ru: {
			'Марка': 'Марка',
			'Любая': 'Любая'
		},
		uk: {
			'Марка': 'Марка',
			'Любая': 'Будь-яка'
		},
	}
};
