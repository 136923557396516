export default {
    name: "ModificationListItem",
    props: {
        blockIndex: {
            type: Number,
            required: true
        },
        id:{
            type: String,
            required: true,
        },
        modification: {
            type: Object,
            required: true
        },
        isChecked: {
            type: Boolean,
            default: false
        }
    },
}
