import {YearsRange} from '../../../../../helpers/Range';
import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Years',
	i18n: require('./i18n').default,
	props: ['indices', 'exclude', 'blockIndex'],
	computed: {
		...mapGetters({
			getByIndex: 'search/state2/getByIndex'
		}),
		list: function () {
			const now = new Date();
			const upperYear = now.getMonth() < 11 ?
				now.getFullYear() :
				now.getFullYear() + 1;
			const range = new YearsRange(1900, upperYear);
			return Array.from(range).reverse();
		},
		groupValue: function () {
			return this.getByIndex({
				name: 'series',
				index: this.indices[0]
			}) || {};
		},
		year: function () {
			return this.groupValue.year || {};
		},
		gte: {
			get: function () {
				return this.year.gte || 0;
			},
			set: function (gte) {
				gte = Number(gte);
				let year = this.year;
				if (gte) {
					year.gte = gte;
				} else {
					delete year.gte;
				}
				if (year.gte && year.lte && year.gte > year.lte) {
					[year.gte, year.lte] = [year.lte, year.gte];
				}
				this.update(year);
			}
		},
		lte: {
			get: function () {
				return this.year.lte || 0;
			},
			set: function (lte) {
				lte = Number(lte);
				let year = this.year;
				if (lte) {
					year.lte = lte;
				} else {
					delete year.lte;
				}
				if (year.gte && year.lte && year.gte > year.lte) {
					[year.gte, year.lte] = [year.lte, year.gte];
				}
				this.update(year);
			}
		}
	},
	methods: {
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		update(year) {
			for (let index of this.indices) {
				let group = this.getByIndex({
					name: 'series',
					index
				}) || {};
				if (Object.keys(year)) {
					group.year = year;
				} else {
					delete group.year;
				}
				if (this.exclude) {
					group.not = true;
				}
				this.addByIndex({
					name: 'series',
					index: index,
					value: group
				});
			}
		},
		remove: function () {
			this.$emit('remove', this.index);
		}
	}
};
