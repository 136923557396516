export default {
	messages: {
		ru: {
			'Область': 'Область',
			'выберите регион': 'выберите регион'
		},
		uk: {
			'Область': 'Область',
			'выберите регион': 'оберіть регіон'
		},
	}
};
