import {mapGetters, mapMutations} from 'vuex';

const {get, set} = require('../../../../helpers/cookie');

const EXPIRES = 604800;

export default {
	name: 'Size',
	i18n: require('./i18n').default,
	data: function () {
		return {
			defaultSize: 20
		};
	},
	computed: {
		...mapGetters({
			getSize: 'search/state2/getSize'
		}),
		size: {
			get: function () {
				return this.getSize({defaultSize: this.defaultSize});
			},
			set: function (value) {
				this.setSize({value, defaultSize: this.defaultSize});
				set('ipp', Number(value), { expires: EXPIRES });
			}
		},
	},
	methods: {
		...mapMutations({
			setSize: 'search/state2/setSize'
		}),
	},
	mounted() {
		this.defaultSize = get('ipp') || 20;
	}
};
