export default {
	messages: {
		ru: {
			'Количество осей': 'Количество осей',
			'Любое': 'Любое',
			'больше': 'больше'
		},
		uk: {
			'Количество осей': 'Кількість осей',
			'Любое': 'Будь-яка',
			'больше': 'більше'
		},
	}
};
