import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Checks',
  i18n: require('./i18n').default,
  computed: {
    ...mapGetters({
      getVerifiedVIN: 'search/state2/getVerifiedVIN',
      getPlateNumber: 'search/state2/getPlateNumber',
      getFirstTime: 'search/state2/getFirstTime',
      getIndices: 'search/state2/getIndices',
      getSearchQuery: 'search/state2/getSearchQuery',
      getFuel: 'search/state2/getFuel',
    }),
    verifiedVinGaActionName() {
      return ['Perevirenyi_VIN_', !this.verifiedVIN ? 'set' : 'unset', '_checkbox'].join('');
    },
    plateNumberGaActionName() {
      return ['Gos_Nomer_', !this.plateNumber ? 'set' : 'unset', '_checkbox'].join('');
    },
    firstTimeGaActionName() {
      return ['First_Time', !this.firstTime ? 'set' : 'unset', '_checkbox'].join('');
    },
    verifiedVIN: {
      get() {
        return this.getVerifiedVIN;
      },
      set(value) {
        this.setVerifiedVIN(value);
      },
    },
    plateNumber: {
      get() {
        return this.getPlateNumber;
      },
      set(value) {
        this.setPlateNumber(value);
      },
    },
    firstTime: {
      get() {
        return this.getFirstTime;
      },
      set(value) {
        this.setFirstTime(value);
      },
    },
    fuelElectro: {
      get() {
        return this.getFuel.includes(6);
      },
      set(value) {
        this.setFuel({ value: 6, set: value });
      },
    },
    indexName: {
      get() {
        return this.getIndices;
      },
      set(value) {
        this.setIndices(value);
      },
    },
  },
  watch: {
    indexName(value) {
      if (value === 'order_auto') {
        // для переходу з червоної форми
        // сетапим параметри "Авто не в Україні" і "Нерозмитнена"
        this.setAbroad('1');
        this.setCustom('0');
        this.setPlateNumber('0');
        this.setVerifiedVIN('0');
      }
    },
  },
  methods: {
    ...mapMutations({
      setVerifiedVIN: 'search/state2/setVerifiedVIN',
      setPlateNumber: 'search/state2/setPlateNumber',
      setFirstTime: 'search/state2/setFirstTime',
      setIndices: 'search/state2/setIndices',
      setAbroad: 'search/state2/setAbroad',
      setCustom: 'search/state2/setCustom',
    }),
    ...mapActions({
      setFuel: 'search/state2/setFuel',
    }),
    toggleIndex(index) {
      switch (index) {
        case 'order_auto':
          // сетапим параметри "Авто не в Україні" і "Нерозмитнена"
          this.setAbroad('1');
          this.setCustom('0');
          this.setPlateNumber('0');
          this.setVerifiedVIN('0');
          this.gaEvent('New_Advanced_Search', 'Order', 'general_search');
          break;
        case 'newauto_search':
          // для нових авто перекидаємо на розширений пошук "нових авто"
          // з відповідними параметрами
          // так як в нових авто нема мульти марок моделей, прокидуємо тільки одну (першу)
          // eslint-disable-next-line no-case-declarations
          const newAutoSearchParams = {
            category_id: this.getSearchQuery['categories.main.id'],
            markaId: this.getSearchQuery['brand.id[0]'],
            modelId: this.getSearchQuery['model.id[0]'],
            yearFrom: this.getSearchQuery['year[0].gte'],
            yearTo: this.getSearchQuery['year[0].lte'],
          };
          // eslint-disable-next-line no-case-declarations
          const sq = Object.keys(newAutoSearchParams).filter((param) => newAutoSearchParams[param]).map((param) => `${param}=${newAutoSearchParams[param]}`).join('&');
          // eslint-disable-next-line no-case-declarations
          const newAutoAdvancedSearchUrl = `${this.langPrefix}/newauto/advanced-search/${sq ? `?${sq}` : ''}`;
          this.setIndices('auto');
          this.gaEvent('New_Advanced_Search', 'New', 'general_search');
          location.href = newAutoAdvancedSearchUrl;
          break;
        case 'auto,order_auto,newauto_search':
          // розсетаплюємо параметри "Авто не в Україні" і "Нерозмитнена"
          // так, як для загального пошуку і пошуку по БУ це дефолтні значення
          this.setAbroad(0);
          this.setCustom(1);
          this.gaEvent('New_Advanced_Search', 'All', 'general_search');
          break;
        case 'auto':
          this.setAbroad(0);
          this.setCustom(1);
          this.gaEvent('New_Advanced_Search', 'Bu', 'general_search');
          break;
        default:
          break;
      }
    },
  },
};
