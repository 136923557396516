export default {
	messages: {
		ru: {
			'Пригнан из': 'Пригнан из',
			'Выберите страну': 'Выберите страну',
			'Любая': 'Любая'
		},
		uk: {
			'Пригнан из': 'Пригнаний з',
			'Выберите страну': 'Оберіть країну',
			'Любая': 'Будь-яка'
		},
	}
};
