export default {
	messages: {
		uk: {
			'Всі': 'Всі',
			'Перевірені додатково': 'Перевірені додатково',
			'Перевірки VIN+, VIN MAX, CARFAX': 'Перевірки VIN+, VIN MAX, CARFAX',
			'Технічно на СТО': 'Технічно на СТО',
			'Виїзна перевірка AUTO.RIA': 'Виїзна перевірка AUTO.RIA',
		},
		ru: {
			'Всі': 'Все',
			'Перевірені додатково': 'Проверены дополнительно',
			'Перевірки VIN+, VIN MAX, CARFAX': 'Проверки VIN+, VIN MAX, CARFAX',
			'Технічно на СТО': 'Технически на СТО',
			'Виїзна перевірка AUTO.RIA': 'Выездная проверка AUTO.RIA',
		},
	}
};
