export default {
	messages: {
		ru: {
			'Мощность': 'Мощность',
			'От': 'От',
			'До': 'До',
			'л.с.': 'л.с.'
		},
		uk: {
			'Мощность': 'Потужність',
			'От': 'Від',
			'До': 'До',
			'л.с.': 'к.с.'
		},
	}
};
