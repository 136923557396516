export default {
	messages: {
		ru: {
			'Продавец': 'Продавец',
			'Все': 'Все',
			'Частное лицо': 'Частное лицо',
			'Компания': 'Компания'
		},
		uk: {
			'Продавец': 'Продавець',
			'Все': 'Всі',
			'Частное лицо': 'Приватна особа',
			'Компания': 'Компанія'
		},
	}
};
