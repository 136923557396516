import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'FuelConsumption',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getFuelConsumption: 'search/state2/getFuelConsumption',
			getFuelConsumptionType: 'search/state2/getFuelConsumptionType',
			getFuel: 'search/state2/getFuel',
		}),
		isElectric() {
			return Array.isArray(this.getFuel) && this.getFuel.length === 1 && this.getFuel[0] === 6;
		},
		gte: {
			get() {
				return this.getFuelConsumption.gte;
			},
			set(value) {
				let payload = this.getFuelConsumption;
				payload.gte = value;
				this.setFuelConsumption(payload);
			}
		},
		lte: {
			get() {
				return this.getFuelConsumption.lte;
			},
			set(value) {
				let payload = this.getFuelConsumption;
				payload.lte = value;
				this.setFuelConsumption(payload);
			}
		},
		type: {
			get() {
				return this.getFuelConsumption.type;
			},
			set(value) {
				let payload = this.getFuelConsumption;
				payload.type = value;
				this.setFuelConsumption(payload);
			}
		},
		powerReserve: {
			get() {
				return this.getFuelConsumptionType('powerReserve');
			},
			set({ gte = this.gtePR, lte = this.ltePR } = {}) {
				this.setFuelConsumption({type: 'powerReserve', gte: gte, lte: lte});
			}
		},
		gtePR: {
			get() {
				return this.powerReserve.gte;
			},
			set(value) {
				this.powerReserve = { gte: value };
			}
		},
		ltePR: {
			get() {
				return this.powerReserve.lte;
			},
			set(value) {
				this.powerReserve = { lte: value };
			}
		},

		accumulatorCapacity: {
			get() {
				return this.getFuelConsumptionType('accumulatorCapacity');
			},
			set({ gte = this.gteAC, lte = this.lteAC } = {}) {
				this.setFuelConsumption({type: 'accumulatorCapacity', gte: gte, lte: lte});
			}
		},
		gteAC: {
			get() {
				return this.accumulatorCapacity.gte;
			},
			set(value) {
				this.accumulatorCapacity = { gte: value };
			}
		},
		lteAC: {
			get() {
				console.log('ac', this.accumulatorCapacity);

				return this.accumulatorCapacity.lte;
			},
			set(value) {
				this.accumulatorCapacity = { lte: value };
			}
		},
	},
	watch: {
		isElectric(value) {
			// Зачищаем предыдущие настройки
			if(value) {
				this.setFuelConsumption({type: 'city'});
				this.setFuelConsumption({type: 'route'});
				this.setFuelConsumption({type: 'combine'});
			} else {
				this.setFuelConsumption({type: 'accumulatorCapacity'});
				this.setFuelConsumption({type: 'powerReserve'});
			}
		}
	},
	methods: {
		...mapMutations({
			setFuelConsumption: 'search/state2/setFuelConsumption'
		})
	}
};
