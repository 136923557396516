import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Row',
	i18n: require('./i18n').default,
	props: ['index'],
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getCountryOrigin: 'search/state2/getCountryOrigin0',
			countries: 'shared/countries/l10n',
			country: 'shared/countries/country'
		}),
		value: {
			get() {
				return this.getCountryOrigin[this.index];
			},
			set(id) {
				let value = this.value;
				value.id = id;
				this.setCountryOrigin({
					index: this.index,
					value: value
				});
			}
		},
		selected: {
			get() {
				return this.value.id || 0;
			},
			set(id) {
				this.value = id;
			}
		},
		options() {
			return [
				...Object.assign([], s).splice(0, 10).map(country => this.country(country.id)),
				...Object.values(this.countries)
					.sort((a, b) => a.name.localeCompare(b.name, 'en'))
			];
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/countries/fetch',
			setCountryOrigin: 'search/state2/setCountryOrigin0'
		}),
		init() {
			this.fetch();
		}
	},
	watch: {
		langId() {
			this.init();
		}
	},
	mounted() {
		this.init();
	}
};

let s = [
	{id: '643', cnt: 16151},
	{id: '804', cnt: 13923},
	{id: '158', cnt: 12177},
	{id: '860', cnt: 869},
	{id: '356', cnt: 540},
	{id: '364', cnt: 467},
	{id: '616', cnt: 374},
	{id: '408', cnt: 371},
	{id: '642', cnt: 346},
	{id: '428', cnt: 296},
	{id: '250', cnt: 261},
	{id: '380', cnt: 220},
	{id: '458', cnt: 218},
	{id: '840', cnt: 166},
	{id: '203', cnt: 165},
	{id: '112', cnt: 160},
	{id: '724', cnt: 143},
	{id: '826', cnt: 138},
	{id: '752', cnt: 120},
	{id: '40', cnt: 120},
	{id: '392', cnt: 92},
	{id: '528', cnt: 44},
	{id: '705', cnt: 44},
	{id: '276', cnt: 42},
	{id: '703', cnt: 31},
	{id: '348', cnt: 30},
	{id: '792', cnt: 30},
	{id: '124', cnt: 29},
	{id: '100', cnt: 28},
	{id: '56', cnt: 28},
	{id: '578', cnt: 27},
	{id: '246', cnt: 26},
	{id: '32', cnt: 26},
	{id: '398', cnt: 21},
	{id: '233', cnt: 15},
	{id: '620', cnt: 13},
	{id: '688', cnt: 11},
	{id: '498', cnt: 2}];
