import { mapMutations, mapGetters } from 'vuex';
import { get } from "../../../../helpers/cookie";

export default {
	name: 'Row',
	props: ['indices', 'exclude', 'blockIndex'],
	components: {
		'vue-brand': require('./Brand/index.vue').default,
		'vue-model': require('./Model/index.vue').default,
		'generation-field': require('./GenerationField/index.vue').default,
		'modification-field': require('./ModificationField/index.vue').default,
		'vue-year': require('./Year/index.vue').default
	},
	methods: {
		remove: function () {
			this.delByIndex({
				name: 'series',
				indices: this.indices
			});
		},
		...mapMutations({
			delByIndex: 'search/state2/delByIndex'
		})
	},
	computed:{
		...mapGetters({
			getByIndex: 'search/state2/getByIndex',
			generationsByIndexBlock: 'shared/generation/generationsByIndexBlock',
			modificationByIndexBlock : 'shared/modification/modificationsByIndexBlock'
		}),
		groupValue () {
			return this.getByIndex({
				name: 'series',
				index: this.indices[0]
			}) || {};
		},
		rowData() {
			return this.indices.map(index => {
				return this.getByIndex({
					name: 'series',
					index
				}) || {};
			});
		},
		brandId () {
			let value = this.groupValue;
			return value.brand || null;
		},
		models() {
			return this.rowData.reduce((acc, el) => {
				if (el.hasOwnProperty('model') && !acc.includes(el.model)) acc.push(el.model);
				return acc;
			}, []);
		},
		generations() {
			return this.generationsByIndexBlock(this.blockIndex, this.exclude, this.brandId);
		},
		modifications() {
			return this.modificationByIndexBlock(this.blockIndex, this.exclude, this.brandId);
		},
		years: function () {
			return Object.assign({gte: null, lte: null }, this.groupValue.year);
		},
		hasGeneration(){
			return Boolean(Object.keys(this.generations).length);
		},
		hasModification() {
			if (!this.generations.length) return false;
			return Boolean(Object.keys(this.modifications).length);
		}
	}
};
