export default {
	messages: {
		ru: {
			'Последние поиски': 'Последние поиски',
			'Показать еще': 'Показать еще',
			'поиск': 'поиск | поиска | поисков',
			'Скрыть': 'Скрыть'
		},
		uk: {
			'Последние поиски': 'Останні пошуки',
			'Показать еще': 'Показати ще',
			'поиск': 'пошук | пошука | пошуків',
			'Скрыть': 'Сховати'
		},
	}
};
