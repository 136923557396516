import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'SellerType',
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			getSellerType: 'search/state2/getSellerType'
		}),
		sellerType: {
			get: function () {
				return this.getSellerType;
			},
			set: function (value) {
				this.setSellerType(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setSellerType: 'search/state2/setSellerType'
		}),
	}
};
