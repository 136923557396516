export default {
	name: 'ResultBlock',
	i18n: require('./i18n').default,
	components: {
		'vue-sort': require('./Sort/index.vue').default,
		'vue-filter-by-checking': require('./FilterByChecking/index.vue').default,
		'vue-top': require('./Top/index.vue').default,
		'vue-sold': require('./Sold/index.vue').default,
		'vue-size': require('./Size/index.vue').default,
		'vue-photo-video': require('./PhotoVideo/index.vue').default,
		'vue-advertisement': require('./Advertisement/index.vue').default,
	},
};
