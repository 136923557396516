import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
	init({store}) {
		const brand = store.getters['search/state2/getExchangesBrand'];
		const category = store.getters['search/state2/getExchangesCategory'];
		if (brand) {
			return store.dispatch('shared/models/fetchByBrand', {category, brand});
		}
	},
	name: 'Model',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			brand: 'search/state2/getExchangesBrand',
			category: 'search/state2/getExchangesCategory',
			modelsForBrand: 'shared/models/getByBrand',
			getExchangesModel: 'search/state2/getExchangesModel',
		}),
		options() {
			return Object.values(this.modelsForBrand(this.brand, this.category) || {});
		},
		selected: {
			get() {
				return this.getExchangesModel;
			},
			set(value) {
				this.setExchangesModel(value);
			}
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		},
		brand() {
			this.init();
		}
	},
	methods: {
		...mapMutations({
			setExchangesModel: 'search/state2/setExchangesModel'
		}),
		...mapActions({
			fetchModels: 'shared/models/fetchByBrand',
		}),
		init() {
			if (this.brand) {
				this.fetchModels({category: this.category, brand: this.brand});
			}
		}
	},
	mounted() {
		this.init();
	}
};
