import {mapGetters, mapMutations} from "vuex";

export default {
	name: 'Labels',
	init({store}) {
		return store.dispatch('shared/cities/fetch');
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getValue: 'search/state2/getValue'
		}),
		values: function () {
			return this.getValue({
				name: 'locations'
			}).map(item => item ? item : {});
		},
		cities: function () {
			return this.getValue({
				name: 'locations'
			}).filter(item => item && item.city).map(item => this.city(item.city));
		},
		...mapGetters({
			city: 'shared/cities/city'
		}),
	},
	methods: {
		...mapMutations({
			setValue: 'search/state2/setValue',
			delValue: 'search/state2/delValue',
			resetByIndex: 'search/state2/resetByIndex',
			addByIndex: 'search/state2/addByIndex',
		}),
		remove(city) {
			let
				region = Number(this.city(city.value).state),
				cnt = this.values.filter(item => Number(item.region) === region).length
			;
			for (let i = 0; i < this.values.length; i++) {
				if (this.values[i] && this.values[i].city === city.value) {
					let {region} = this.values[i];
					this.addByIndex({
						name: 'locations',
						index: i,
						value: cnt <= 1 ? {region} : {}
					});
				}
			}
		}
	}
};
