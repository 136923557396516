export default {
	messages: {
		ru: {
			'Участие в ДТП': 'Участие в ДТП',
			'Был в ДТП': 'Был в ДТП',
			'Не был в ДТП': 'Не был в ДТП',
		},
		uk: {
			'Участие в ДТП': 'Участь в ДТП',
			'Был в ДТП': 'Був у ДТП',
			'Не был в ДТП': 'Не був у ДТП',
		},
	}
};
