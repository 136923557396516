import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Fuel',
  i18n: require('./i18n').default,
  init({ store }) {
    const category = store.getters['search/state2/getCategory'] || 1;
    return store.dispatch('shared/fuels/fetch', { category });
  },
  computed: {
    ...mapGetters({
      langId: 'lang/id',
      getFuel: 'search/state2/getFuel',
      getCategory: 'search/state2/getCategory',
      fuels: 'shared/fuels/l10n',
    }),
    checkboxes() {
      return Object.values(this.fuels)
        .sort((a, b) => a.index - b.index)
        .map((fuel) => {
          fuel.checked = this.getFuel.indexOf(fuel.value) >= 0;
          return fuel;
        });
    },
    category() {
      return this.getCategory || 1;
    },
  },
  watch: {
    langId() {
      this.fetchFuels({ category: this.category });
    },
    category() {
      this.fetchFuels({ category: this.category });
    },
  },
  methods: {
    ...mapActions({
      fetchFuels: 'shared/fuels/fetch',
      setFuel: 'search/state2/setFuel',
    }),
    toggleChecked(checkbox) {
      this.setFuel({ value: checkbox.value, set: checkbox.checked });
    },
  },
  mounted() {
    const { category } = this;
    this.fetchFuels({ category });
  },
};
