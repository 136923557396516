export default {
	name: 'Exclude',
	i18n: require('./i18n').default,
	data() {
		return {
			opened: false
		};
	},
	components: {
		'vue-country-origin': require('./CountryOrigin/index.vue').default,
		'vue-brand-model': require('../BrandModel/index.vue').default
	},
	methods: {
		open() {
			this.opened = !this.opened;
		}
	}
};
