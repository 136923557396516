export default {
	messages: {
		ru: {
			'Количество дверей': 'Количество дверей',
			'От': 'От',
			'До': 'До'
		},
		uk: {
			'Количество дверей': 'Кількість дверей',
			'От': 'Від',
			'До': 'До'
		},
	}
};
