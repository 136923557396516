import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'BrandModel',
	i18n: require('./i18n').default,
	props: ['exclude'],
	components: {
		Row: require('./Row/index.vue').default
	},
	computed: {
		title: function () {
			if (this.exclude) {
				return `${this.$t('Исключить марку')}`;
			} else {
				return `${this.$t('Марка, модель, год')}`;
			}
		},
		...mapGetters({
			getValue: 'search/state2/getValue'
		}),
		series: function () {
			return this.getValue({
				name: 'series'
			});
		},
		rows() {
			let hash = {};
			let series = this.series.map((item) => Boolean(item.not) === Boolean(this.exclude) ? item : undefined);
			for (let i = 0; i < series.length; i++) {
				if (series[i]) {
					let {index} = series[i];
					hash[index] = hash[index] || [];
					hash[index].push(i);
				}
			}
			return hash;
		}
	},
	methods: {
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		addRow: function () {
			let max = 0;
			let indices = this.series.map(item => item.index).filter(Number);
			if (indices.length) {
				max = Math.max.apply(null, indices);
			}
			let value = {
				index: max + 1
			};
			if (this.exclude) {
				value.not = true;
			}
			this.addByIndex({
				name: 'series',
				index: this.series.length,
				value
			});
		}
	}
};
