import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Carrying',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getAxle: 'search/state2/getAxle'
		}),
		selected: {
			get() {
				return this.getAxle;
			},
			set(value) {
				this.setAxle(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setAxle: 'search/state2/setAxle'
		})
	}
};
