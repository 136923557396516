import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Paint',
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			getPaint: 'search/state2/getPaint'
		}),
		paint: {
			get() {
				return this.getPaint;
			},
			set: function (value) {
				this.setPaint(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setPaint: 'search/state2/setPaint'
		}),
	}
};
