export default {
	messages: {
		ru: {
			'Модель': 'Модель',
			'Любая': 'Любая'
		},
		uk: {
			'Модель': 'Модель',
			'Любая': 'Будь-яка'
		},
	}
};
