export default {
	messages: {
		ru: {
			'выберите канал, которым будете получать свежие авто': 'выберите канал, которым будете получать свежие авто',
			'Cохранить': 'Cохранить',
			'Подписаться на этот поиск': 'Подписаться на этот поиск',
			'Ваши персональные данные будут обработаны и защищены согласно': 'Ваши персональные данные будут обработаны и защищены согласно',
			'Политики конфиденциальности': 'Политики конфиденциальности',
			'Введите ваш e-mail': 'Введите ваш e-mail',
			'Подписаться': 'Подписаться',
		},
		uk: {
			'выберите канал, которым будете получать свежие авто': 'оберіть канал, яким будете отримувати свіжі авто',
			'Cохранить': 'Зберегти',
			'Подписаться на этот поиск': 'Підписатися на цей пошук',
			'Ваши персональные данные будут обработаны и защищены согласно': 'Ваші персональні дані будуть оброблені та захищені згідно до',
			'Политики конфиденциальности': 'Політики приватності',
			'Введите ваш e-mail': 'Введіть Ваш e-mail',
			'Подписаться': 'Підписатись',
		},
	}
};
