import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Abroad',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getIndices: 'search/state2/getIndices',
			getAbroad: 'search/state2/getAbroad'
		}),
		abroad: {
			get() {
				return this.getAbroad;
			},
			set(value) {
				this.setAbroad(value);
			}
		},
		indexName: {
			get: function () {
				return this.getIndices;
			},
			set: function (value) {
				this.setIndices(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setIndices: 'search/state2/setIndices',
			setAbroad: 'search/state2/setAbroad'
		}),
	}
};
