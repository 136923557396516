export default {
	messages: {
		ru: {
			'Количество мест': 'Количество мест',
			'От': 'От',
			'До': 'До'
		},
		uk: {
			'Количество мест': 'Кількість місць',
			'От': 'Від',
			'До': 'До'
		},
	}
};
