export default {
	messages: {
		ru: {
			'Актуальность': 'Актуальность',
			'Все': 'Все',
			'Скрыть проданные': 'Скрыть проданные',
			'Только проданные': 'Только проданные'
		},
		uk: {
			'Актуальность': 'Актуальність',
			'Все': 'Всі',
			'Скрыть проданные': 'Приховати продані',
			'Только проданные': 'Тільки продані'
		},
	}
};
