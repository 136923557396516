export default {
	messages: {
		ru: {
			'Грузоподъемность': 'Грузоподъемность',
			'От': 'От',
			'До': 'До'
		},
		uk: {
			'Грузоподъемность': 'Вантажопідйомність',
			'От': 'Від',
			'До': 'До'
		},
	}
};
