import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Wheel',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getWheel: 'search/state2/getWheel'
		}),
		selected: {
			get() {
				return this.getWheel;
			},
			set(value) {
				this.setWheel(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setWheel: 'search/state2/setWheel'
		})
	}
};
