import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Credit',
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			getCredit: 'search/state2/getCredit'
		}),
		credit: {
			get() {
				return this.getCredit;
			},
			set(value) {
				this.setCredit(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setCredit: 'search/state2/setCredit'
		}),
	}
};
