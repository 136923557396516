import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Carrying',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getCarrying: 'search/state2/getCarrying'
		}),
		gte: {
			get() {
				return this.getCarrying.gte;
			},
			set(value) {
				let payload = this.getCarrying;
				payload.gte = value;
				this.setCarrying(payload);
			}
		},
		lte: {
			get() {
				return this.getCarrying.lte;
			},
			set(value) {
				let payload = this.getCarrying;
				payload.lte = value;
				this.setCarrying(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setCarrying: 'search/state2/setCarrying'
		})
	}
};
