export default {
	messages: {
		ru: {
			'Сортировка': 'Сортировка',
			'Обычная': 'Обычная',
			'От дешевых к дорогим': 'От дешевых к дорогим',
			'От дорогих к дешевым': 'От дорогих к дешевым',
			'Дата добавления': 'Дата добавления',
			'Год выпуска, по возрастанию': 'Год выпуска, по возрастанию',
			'Год выпуска, по убыванию': 'Год выпуска, по убыванию',
			'Пробег, по возрастанию': 'Пробег, по возрастанию',
			'Пробег, по убыванию': 'Пробег, по убыванию'
		},
		uk: {
			'Сортировка': 'Сортування',
			'Обычная': 'Звичайне',
			'От дешевых к дорогим': 'Від дешевих до дорогих',
			'От дорогих к дешевым': 'Від дорогих до дешевих',
			'Дата добавления': 'Дата додавання',
			'Год выпуска, по возрастанию': 'Рік випуску, за зростанням',
			'Год выпуска, по убыванию': 'Рік випуску, за спаданням',
			'Пробег, по возрастанию': 'Пробіг, за зростанням',
			'Пробег, по убыванию': 'Пробіг, за спаданням'
		},
	}
};
