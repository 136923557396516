import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Confiscated',
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			getConfiscated: 'search/state2/getConfiscated'
		}),
		confiscated: {
			get() {
				return this.getConfiscated;
			},
			set(value) {
				this.setConfiscated(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setConfiscated: 'search/state2/setConfiscated'
		}),
	}
};
