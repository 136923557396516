export default {
    name: "ModificationList",
    components: {
        'modification-item': require('./Item/index.vue').default
    },
    props: {
        blockIndex: {
            type: Number,
            required: true
        },
        modificationsList: {
            type: Array,
            required: true
        },
        selectedModification: {
            type: Array,
            default: []
        },
        excludePrefix: {
            type: String,
            default: false
        }
    },
    methods:{
        generateId(id){
            return `modification-${this.blockIndex}-${id}${this.excludePrefix}`;
        }
    }
}
