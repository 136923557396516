export default {
	messages: {
		ru: {
			'Вы ищете': 'Вы ищете',
			'Ещё': 'Ещё',
			'Сбросить все фильтры': 'Сбросить все фильтры'
		},
		uk: {
			'Вы ищете': 'Ви шукаєте',
			'Ещё': 'Ще',
			'Сбросить все фильтры': 'Скинути всі фільтри'
		},
	}
};
