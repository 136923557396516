import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Payment',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getExchangesPayment: 'search/state2/getExchangesPayment',
		}),
		selected: {
			get() {
				return this.getExchangesPayment;
			},
			set(value) {
				this.setExchangesPayment(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setExchangesPayment: 'search/state2/setExchangesPayment',
		})
	}
};
