import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Label',
  i18n: require('./i18n').default,
  props: ['label'],
  data() {
    return {
      staticParams: {
        paintCondition: {
          1: this.$t('Как новое'),
          2: this.$t('Профессионально исправленные следы использования'),
          3: this.$t('Неисправленные следы использования'),
        },
        technicalCondition: {
          1: this.$t('Полностью неповрежденное'),
          2: this.$t('Профессионально отремонтированные повреждения'),
          3: this.$t('Не отремонтированные повреждения'),
          4: this.$t('Не на ходу / На запчасти'),
        },
        'post.exchanges.type.id': {
          1: this.$t('Возможен обмен на автомобиль'),
        },
        'post.exchanges.payment.id': {
          1: this.$t('Моя доплата'),
          2: this.$t('Хочу доплату'),
          3: this.$t('Равноценный обмен'),
        },
        installment: {
          1: this.$t('Возможна оплата частями'),
        },
        'abroad.not': {
          0: this.$t('Авто в Украине'),
          1: this.$t('Авто не в Украине'),
        },
        'country.import.usa.not': {
          0: this.$t('Показать авто из США'),
          1: this.$t('Скрыть авто из США'),
        },
        'custom.not': {
          0: this.$t('Нерастаможенные авто'),
          1: this.$t('Растаможенные'),
        },
        credit: {
          0: this.$t('Не в кредит'),
          1: this.$t('Только кредитные'),
        },
        confiscated: {
          0: this.$t('Не конфискованные'),
          1: this.$t('Конфискованные'),
        },
        'color.metallic': {
          1: this.$t('Металлик'),
        },
        'damage.not': {
          0: this.$t('Битые'),
          1: this.$t('Небитые'),
        },
        spareParts: {
          0: this.$t('Скрыть не на ходу'),
          1: this.$t('Только не на ходу'),
        },
        sellerType: {
          1: this.$t('Частное лицо'),
          2: this.$t('Компания'),
        },
        'sort[0].order': {
          'price.asc': this.$t('От дешевых к дорогим'),
          'price.desc': this.$t('От дорогих к дешевым'),
          'dates.created.desc': this.$t('Дата добавления'),
          'year.asc': this.$t('Год выпуска, по возрастанию'),
          'year.desc': this.$t('Год выпуска, по убыванию'),
          'mileage.asc': this.$t('Пробег, по возрастанию'),
          'mileage.desc': this.$t('Пробег, по убыванию'),
        },
        top: {
          1: `${this.$tc('За')} ${this.$tc('час', 1)}`,
          8: `${this.$tc('За')} 3 ${this.$tc('час', 3)}`,
          9: `${this.$tc('За')} 6 ${this.$tc('час', 6)}`,
          14: `${this.$tc('За')} 12 ${this.$tc('час', 12)}`,
          2: `${this.$tc('За')} ${this.$t('сегодня')}`,
          11: `${this.$tc('За')} ${this.$t('сутки')}`,
          10: `${this.$tc('За')} 2 ${this.$t('дня')}`,
          3: `${this.$tc('За')} 3 ${this.$t('дня')}`,
          4: `${this.$tc('За')} ${this.$t('неделю')}`,
          5: `${this.$tc('За')} ${this.$tc('месяц', 1)}`,
          6: `${this.$tc('За')} 3 ${this.$tc('месяц', 3)}`,
        },
        'dates.sold.not': {
          '0000-00-00 00:00:00': this.$t('Скрыть проданные'),
        },
        'dates.sold': {
          '0000-00-00 00:00:00': this.$t('Только проданные'),
        },
        size: {
          10: `${this.$t('По')} 10`,
          20: `${this.$t('По')} 20`,
          30: `${this.$t('По')} 30`,
          50: `${this.$t('По')} 50`,
          100: `${this.$t('По')} 100`,
        },
        'photos.all.id.gte': {
          1: this.$t('Только с фото'),
        },
        'video.key.gte': {
          1: this.$t('Только с видео'),
        },
        'wheel.id': {
          1: '4x2',
          2: '4x4',
          3: '6x2',
          4: '6x4',
          5: '6x6',
          6: '8x2',
          7: '8x4',
          8: '8x6',
          9: '8x8',
          10: '10x4',
          11: '10x8',
        },
        axle: {
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: `${this.$t('больше')} 4`,
        },
        with_autotest_tech_check: {
          1: `${this.$t('Технічно на СТО')}`,
        },
        with_autotest_vin_check: {
          1: `${this.$t('Перевірки VIN+, VIN MAX, CARFAX')}`,
        },
        with_autotest_corporate_check: {
          1: `${this.$t('Виїзна перевірка AUTO.RIA')}`,
        },
      },
    };
  },
  computed: {
    text() {
      if (this.staticParams[this.label.name]) {
        return this.staticParams[this.label.name][this.label.value];
      }
      return this.storeParams();
    },
    ...mapGetters({
      city: 'shared/cities/city',
      region: 'shared/locations/region',
      country: 'shared/countries/country',
      category: 'shared/categories/category',
      brand: 'shared/brands/brand',
      model: 'shared/models/model',
      generationIds: 'shared/generation/generationsIds',
      modificationIds: 'shared/modification/modificationsIds',
      body: 'shared/bodies/body',
      drive: 'shared/drives/drive',
      ecoStandart: 'shared/ecoStandarts/ecoStandart',
      colorType: 'shared/colorTypes/colorType',
      gearbox: 'shared/gearboxes/gearbox',
      fuel: 'shared/fuels/fuel',
      color: 'shared/colors/color',
      option: 'shared/options/option',
      getByIndex: 'search/state2/getByIndex',
    }),
  },
  updated() {
    if (this.$el.childNodes[0]) {
      this.$emit('changeOffsetTop', {
        name: this.label.name,
        offsetTop: this.$el.childNodes[0].offsetTop,
      });
    }
  },
  mounted() {
    if (this.$el.childNodes[0]) {
      this.$emit('changeOffsetTop', {
        name: this.label.name,
        offsetTop: this.$el.childNodes[0].offsetTop,
      });
    }
  },
  methods: {
    ...mapActions({
      setCategory: 'search/state2/setCategory',
      setExchange: 'search/state2/setExchange',
      setExchangesCategory: 'search/state2/setExchangesCategory',
      setExchangesBrand: 'search/state2/setExchangesBrand',
      deleteGeneration: 'shared/generation/deleteGeneration',
      deleteGenerationFromSeries: 'shared/generation/deleteGenerationFromSeries',
      deleteModificationList: 'shared/modification/deleteModificationList',
      deleteModificationListFromSeries: 'shared/modification/deleteModificationFromSeries',

    }),
    ...mapMutations({
      setValue: 'search/state2/setValue',
      delValue: 'search/state2/delValue',
      addByIndex: 'search/state2/addByIndex',
    }),
    mousemove() {
      this.$emit('canDelete', this.label);
    },
    mouseleave() {
      this.$emit('canDelete', {});
    },
    remove() {
      const labelName = this.label.name
        .replace(/[\.,]+/g, '_');
      this.gaEvent('New_Advanced_Search', labelName, 'Delete_options');
      if (this.label.name === 'post.exchanges.type.id') {
        if (this.label.value === 1) {
          this.setExchange(1);
        } else {
          this.setExchange(2);
        }
      } else if (this.label.name === 'post.exchanges.filters.category') {
        this.setExchangesCategory(0);
      } else if (this.label.name === 'post.exchanges.filters.brand') {
        this.setExchangesBrand(0);
      } else if (this.label.name === 'categories.main.id') {
        this.setCategory(0);
      } else if (this.label.name === 'country.origin') {
        const { not } = this.getByIndex({
          name: 'country.origin',
          index: this.label.index,
        }) || {};
        this.addByIndex({
          name: 'country.origin',
          index: this.label.index,
          value: {
            not,
          },
        });
      } else if (this.label.name === 'brand') {
        const { year, not } = this.getByIndex({
          name: 'series',
          index: this.label.index,
        }) || {};
        this.addByIndex({
          name: 'series',
          index: this.label.index,
          value: {
            year,
            not,
          },
        });
        this.deleteGeneration({
          blockIndex: this.label.index,
          brandId: this.label.value,
          exclude: Boolean(not),
        });
        this.deleteModificationList({
          blockIndex: this.label.index,
          exclude: Boolean(not),
          brandId: this.label.value,
        });
      } else if (this.label.name === 'model') {
        const {
          year, brand, not, index: blockIndex,
        } = this.getByIndex({
          name: 'series',
          index: this.label.index,
        }) || {};
        this.addByIndex({
          name: 'series',
          index: this.label.index,
          value: {
            year,
            brand,
            not,
          },
        });
        this.deleteGeneration({
          blockIndex,
          exclude: Boolean(not),
          brandId: this.label.brand,
          modelId: this.label.value,
        });
        this.deleteModificationList({
          blockIndex,
          exclude: Boolean(not),
          brandId: brand,
          modelId: this.label.value,
        });
      } else if (this.label.name === 'generation') {
        const seriesData = this.getByIndex({
          name: 'series',
          index: this.label.index,
        }) || {};
        this.deleteGenerationFromSeries({
          seriesData,
          seriesIndex: this.label.index,
          fieldName: this.label.name,
          generationId: this.label.value,
        });
        this.deleteModificationList({
          blockIndex: this.label.index,
          exclude: Boolean(seriesData.not),
          brandId: seriesData.brand,
          modelId: seriesData.model,
          generationId: this.label.value,
        });
      } else if (this.label.name === 'modification') {
        const seriesData = this.getByIndex({
          name: 'series',
          index: this.label.index,
        }) || {};

        this.deleteModificationListFromSeries({
          seriesData,
          seriesIndex: this.label.index,
          fieldName: 'modification',
          modificationData: { value: this.label.value },
        });
      } else if (this.label.name === 'year') {
        const { model, brand, not } = this.getByIndex({
          name: 'series',
          index: this.label.index,
        }) || {};
        this.addByIndex({
          name: 'series',
          index: this.label.index,
          value: {
            model,
            brand,
            not,
          },
        });
      } else if (this.label.name === 'region') {
        this.addByIndex({
          name: 'locations',
          index: this.label.index,
          value: {},
        });
      } else if (this.label.name === 'city') {
        const { region } = this.getByIndex({
          name: 'locations',
          index: this.label.index,
        }) || {};
        this.addByIndex({
          name: 'locations',
          index: this.label.index,
          value: {
            region,
          },
        });
      } else if (this.label.name === 'distance_from_city_km') {
        const locationObj = this.getByIndex({
          name: 'locations',
          index: this.label.index,
        }) || {};
        delete locationObj.distance_from_city_km;
        this.addByIndex({
          name: 'locations',
          index: this.label.index,
          value: {
            ...locationObj,
          },
        });
      } else if (this.label.group) {
        const [gte, lte] = this.label.name.split(',');
        this.setValue({
          name: gte,
        });
        this.setValue({
          name: lte,
        });
      } else if (this.label.array) {
        this.delValue({
          name: this.label.name,
          value: this.label.value,
        });
      } else {
        this.setValue({
          name: this.label.name,
        });
      }
    },
    labelPrice() {
      if (this.label.value.gte && this.label.value.lte) return `${this.label.value.gte} - ${this.label.value.lte}`;
      if (this.label.value.gte) return `${this.$t('от')} ${this.label.value.gte}`;
      if (this.label.value.lte) return `${this.$t('до')} ${this.label.value.lte}`;
      return '';
    },
    fuelConsumptionLabel() {
      return `${this.$t('Расход топлива')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''} л/100 км`;
    },
    engineLabel() {
      return `${this.$t('Объем двигателя')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''} л.`;
    },
    powerLabel(type) {
      return `${this.$t('Мощность')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''} ${type === 'kW' ? 'кВт' : this.$t('л.с.')}`;
    },
    mileageLabel() {
      if (!this.label.value.gte && this.label.value.lte === 0) return this.$t('Без пробігу');
      return `${this.$t('Пробег')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''} ${this.$t('тыс. км')}`;
    },
    carryingLabel() {
      return `${this.$t('Грузоподъемность')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''}`;
    },
    doorsLabel() {
      return `${this.$t('Количество дверей')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''}`;
    },
    seatsLabel() {
      return `${this.$t('Количество мест')}${this.label.value.gte ? ` ${this.$t('от')} ${this.label.value.gte}` : ''}${this.label.value.lte ? ` ${this.$t('до')} ${this.label.value.lte}` : ''}`;
    },
    yearLabel() {
      if (this.label.value.gte && this.label.value.lte) return `${this.label.value.gte} - ${this.label.value.lte} ${this.$t('гг.')}`;
      if (this.label.value.gte) return `${this.$t('год')} ${this.$t('от')} ${this.label.value.gte} ${this.$t('г.')}`;
      if (this.label.value.lte) return `${this.$t('год')} ${this.$t('до')} ${this.label.value.lte} ${this.$t('г.')}`;
      return '';
    },
    storeParams() {
      switch (this.label.name) {
        case 'categories.main.id':
          return this.category(this.label.value).name;
        case 'body.id[i]':
          return this.body(this.label.value).name;
        case 'country.origin':
          return this.country(this.label.value).name;
        case 'country.origin.id[i]':
          return this.country(this.label.value).name;
        case 'brand':
          return this.brand(this.label.value).name;
        case 'model':
          return this.model(this.label.value) ? this.model(this.label.value).name : '';
        case 'generation':
          return this.generationIds && this.generationIds[this.label.value] ? this.generationIds[this.label.value].name : '';
        case 'modification':
          return this.modificationIds && this.modificationIds[this.label.value] ? this.modificationIds[this.label.value].name : '';
        case 'year':
          return this.yearLabel();
        case 'price.USD.gte,price.USD.lte':
          return `${this.labelPrice()} $`;
        case 'price.EUR.gte,price.EUR.lte':
          return `${this.labelPrice()} €`;
        case 'price.UAH.gte,price.UAH.lte':
          return `${this.labelPrice()} грн`;
        case 'post.exchanges.filters.category':
          return this.category(this.label.value).name;
        case 'post.exchanges.filters.subCategory':
          return this.body(this.label.value).name;
        case 'post.exchanges.filters.brand':
          return this.brand(this.label.value).name;
        case 'post.exchanges.filters.model':
          return this.model(this.label.value).name;
        case 'region':
          return this.region(this.label.value).name;
        case 'city':
          return this.city(this.label.value).name;
        case 'distance_from_city_km':
          return `+ ${this.label.value} км`;
        case 'auction':
          return this.$t('Возможен торг');
        case 'country.import.id':
          return this.country(this.label.value).name;
        case 'fuel.id[i]':
          return this.fuel(this.label.value).name;
        case 'gearbox.id[i]':
          return this.gearbox(this.label.value).name;
        case 'drive.id[i]':
          return this.drive(this.label.value).name;
        case 'eco_standart[i]':
          return this.ecoStandart(this.label.value).name;
        case 'color.type[i]':
          return this.colorType(this.label.value).name;
        case 'fuel.consumption.city.gte,fuel.consumption.city.lte':
          return this.fuelConsumptionLabel();
        case 'fuel.consumption.route.gte,fuel.consumption.route.lte':
          return this.fuelConsumptionLabel();
        case 'fuel.consumption.combine.gte,fuel.consumption.combine.lte':
          return this.fuelConsumptionLabel();
        case 'engine.gte,engine.lte':
          return this.engineLabel();
        case 'power.kW.gte,power.kW.lte':
          return this.powerLabel('kW');
        case 'power.hp.gte,power.hp.lte':
          return this.powerLabel('hp');
        case 'mileage.gte,mileage.lte':
          return this.mileageLabel();
        case 'carrying.gte,carrying.lte':
          return this.carryingLabel();
        case 'doors.gte,doors.lte':
          return this.doorsLabel();
        case 'seats.gte,seats.lte':
          return this.seatsLabel();
        case 'color.id[i]':
          return this.color(this.label.value).name;
        case 'options.id[i]':
          return this.option(this.label.value).name;
        default:
          return '';
      }
    },
  },
};
