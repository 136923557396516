import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'Body',
	i18n: require('./i18n').default,
	data: function () {
		return {
			opened_: false
		};
	},
	init({store}) {
		const category = store.getters['search/state2/getCategory'];
		const series = store.getters['search/state2/getValue']('series') || [];
		const models = series.map(item => !item.not && item.model).filter(Number);
		return Promise.all([
			store.dispatch('shared/bodies/fetch', {category}),
			Promise.all[models.map(model => store.dispatch('shared/relation/bodyModelFetch', {model}))]
		]);
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getCategory: 'search/state2/getCategory',
			getEquipmentType: 'search/state2/getEquipmentType',
			getBody: 'search/state2/getBody',
			getValue: 'search/state2/getValue',
			bodiesByCategory: 'shared/bodies/bodiesByCategory',
			bodiesByModel: 'shared/relation/bodiesByModel',
		}),
		bodies() {
			return this.bodiesByCategory(this.category, this.getEquipmentType);
		},
		bodyModel() {
			let hash = {};
			this.models.forEach((model) => {
				let bodiesByModel = this.bodiesByModel(model) || [];
				bodiesByModel.forEach((body) => {
					if (body) {
						hash[body] = true;
					}
				});
			});
			return hash;
		},
		checkboxes() {
			let bodies = this.bodies
				.map((body) => {
					body.checked = this.getBody.indexOf(body.value) >= 0;
					if (Object.keys(this.bodyModel).length) {
						if (body.checked || this.bodyModel[body.value]) {
							return body;
						}
					} else {
						return body;
					}
				}).filter(Boolean);
			if (this.category !== 1) {
				bodies = bodies.sort((a, b) => a.name.localeCompare(b.name));
			}
			for (let id of [140, 107, 28, 56, 213, 251, 229, 64, 302]) {
				let index = bodies.findIndex((body) => {
					return body.value === id;
				});
				bodies.push(...bodies.splice(index, 1));
			}
			return bodies;
		},
		category: function () {
			return this.getCategory || 1;
		},
		series() {
			return this.getValue('series') || [];
		},
		models() {
			return this.series.map(item => !item.not && item.model).filter(Number);
		},
		showOpenButton() {
			return this.checkboxes.length > 6;
		},
		threeCol() {
			return this.category === 1;
		},
		opened: {
			set(value) {
				if (!value && (this.category === 4 || this.category === 10)) {
					window.scrollTo(0, this.$el.scrollHeight);
				}
				this.opened_ = value;
			},
			get() {
				return this.opened_;
			}
		}
	},
	watch: {
		langId() {
			this.fetchBodies({category: this.category});
		},
		category() {
			this.fetchBodies({category: this.category});
		},
		models() {
			this.models.map(model => this.bodyModelFetch({model}));
		}
	},
	methods: {
		...mapActions({
			fetchBodies: 'shared/bodies/fetch',
			bodyModelFetch: 'shared/relation/bodyModelFetch',
			setBody: 'search/state2/setBody',
		}),
		toggleChecked(checkbox) {
			this.setBody({value: checkbox.value, set: checkbox.checked});
		}
	},
	mounted() {
		this.models.map(model => this.bodyModelFetch({model}));
		this.fetchBodies({category: this.category});
	}
};
