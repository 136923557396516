export default {
    name: 'ModelGenerations',
    components: {
        'model-generation-item': require('./Item/index.vue').default
    },
    props: {
        blockIndex: {
            type: Number,
            required: true
        },
        modelTitle: {
            type: String,
            required: true
        },
        generations: {
            type: Array,
            required: true
        },
        selectedGenerations: {
            type: Array,
            default: []
        },
        excludePrefix: {
            type: String,
            default: false
        }
    },
    methods:{
        generateId(id){
            return `generation-${this.blockIndex}-${id}${this.excludePrefix}`;
        }
    }
}
