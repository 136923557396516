export default {
	messages: {
		ru: {
			'Авто не в Украине (под заказ)': 'Авто не в Украине (под заказ)',
			'Все': 'Все',
			'Показать': 'Показать',
			'Скрыть': 'Скрыть'
		},
		uk: {
			'Авто не в Украине (под заказ)': 'Авто не в Україні (під замовлення)',
			'Все': 'Всі',
			'Показать': 'Показати',
			'Скрыть': 'Сховати'
		},
	}
};
