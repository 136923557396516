import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Power',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getPower: 'search/state2/getPower'
		}),
		gte: {
			get() {
				return this.getPower.gte;
			},
			set(value) {
				let payload = this.getPower;
				payload.gte = value;
				this.setPower(payload);
			}
		},
		lte: {
			get() {
				return this.getPower.lte;
			},
			set(value) {
				let payload = this.getPower;
				payload.lte = value;
				this.setPower(payload);
			}
		},
		type: {
			get() {
				return this.getPower.type;
			},
			set(value) {
				let payload = this.getPower;
				payload.type = value;
				this.setPower(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setPower: 'search/state2/setPower'
		})
	}
};
