import isChildElement from '../../../helpers/isChildElement';
import WindowEventWatcher from '../../../helpers/WindowEventWatcher';
import loopify from '../../../../app/helpers/loopify';

let watcher = new WindowEventWatcher('click');

export default {
	name: 'Autocomplete',
	data() {
		return {
			isOptionsHidden: true,
			inputText__: '',
			hoveredIndex__: undefined,
			value__: 0
		};
	},
	metaInfo() {
		return {
			bodyAttrs: {
				'class': this.mobilePopupActive ? 'autocomplete-popup' : ''
			}
		};
	},
	props: [
		'value', 'options',
		'label', 'placeholder',
		'postfix', 'resetInput',
		'mode'
	],
	computed: {
		optionsList() {
			let i = 0;
			return Array.isArray(this.options) ?
				this.options.map(({label, options} = {}) => {
					const optionsNew = options.map(({value, label} = {}) => ({value, label, index: i++}));
					return {label, options: optionsNew};
				}) : [];
		},
		optionsListFlat() {
			return this.optionsList.reduce((a, {options}) => {
				a = Array.isArray(options) ? a.concat(options) : a;
				return a;
			}, []);
		},
		selectedOption() {
			return this.optionsListFlat.find(({value} = {}) => Number(value) === Number(this.value__));
		},
		inputText: {
			get: function () {
				if (this.mode === 'autocomplete') {
					return this.inputText__;
				} else {
					return (this.selectedOption || {}).label;
				}
			},
			set: function (value) {
				this.inputText__ = value;
				this.$emit('input', value);
			}
		},
		mobilePopupActive() {
			return this.isMobile && !this.isOptionsHidden;
		},
		hoveredIndex: {
			get: function () {
				return this.$data.hoveredIndex__;
			},
			set: function (value) {
				this.$data.hoveredIndex__ = Number.isFinite(value) ?
					loopify(value, this.optionsListFlat.length) :
					undefined;
			}
		}
	},
	methods: {
		hideOptionsList() {
			this.isOptionsHidden = true;
		},
		showOptionsList() {
			this.isOptionsHidden = false;
		},
		change(value) {
			this.inputText__ = undefined;
			this.value__ = value;
			this.$emit('change', value);
		},
		select(value) {
			this.change(value);
			this.hideOptionsList();
			let {index} = this.selectedOption || {};
			this.shiftListTo(index);
		},
		esc() {
			this.hoveredIndex = undefined;
			this.$emit('esc');
			this.hideOptionsList();
		},
		drop() {
			this.change(undefined);
		},
		input(event) {
			const {target: {value} = {}} = event;
			if (value) {
				// Костыль для из-за странного поведения chrome на мобильном
				this.$emit('input', value);
				this.inputText__ = value;
				this.showOptionsList();
			}
		},
		keydown(event) {
			switch (event.keyCode) {
				case 9: { // tab
					this.hideOptionsList();
					return;
				}
				case 13: { // enter
					let {value} = this.optionsListFlat[this.hoveredIndex] || {};
					this.select(value);
					this.hoveredIndex = undefined;
					event.preventDefault();
					return;
				}
				case 27: { // esc
					this.esc();
					return;
				}
				case 38: { // up
					if (this.isOptionsHidden) {
						this.showOptionsList();
					} else {
						this.hoveredIndex = Number.isFinite(this.hoveredIndex) ? this.hoveredIndex - 1 : -1;
						this.shiftListTo(this.hoveredIndex);
					}
					event.preventDefault();
					return;
				}
				case 40: { // down
					if (this.isOptionsHidden) {
						this.showOptionsList();
					} else {
						this.hoveredIndex = Number.isFinite(this.hoveredIndex) ? this.hoveredIndex + 1 : 0;
						this.shiftListTo(this.hoveredIndex);
					}
					event.preventDefault();
					return;
				}
			}
		},
		shiftListTo(hoveredIndex) {
			const ListHolder = this.$refs.ListHolder;
			const {[hoveredIndex]: item} = ListHolder.children ? ListHolder.children : [];
			if (item) {
				ListHolder.scrollTop = item.offsetTop;
			}
		}
	},
	watch: {
		value(value) {
			this.value__ = value;
		},
		resetInput() {
			this.change(undefined);
		}
	},
	mounted() {
		let node = this.$refs.pseudoselect;
		const hide = function ({target} = {}) {
			if (target !== node && !isChildElement(node, target)) {
				this.esc();
			}
		}.bind(this);
		watcher.subscribe(node, hide);

		this.value__ = this.value;
	},
	created() {
		this.value__ = this.value;
	},
	beforeDestroy() {
		watcher.unsubscribe(this.$refs.pseudoselect);
	}
};
