import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
	init({store}) {
		return store.dispatch('shared/cities/fetch');
	},
	name: 'City',
	i18n: require('./i18n').default,
	props: ['index'],
	data: function () {
		return {
			mode: 'list',
			isOptionsHidden: true,
			suggesting: '',
			selectedName: '',
			resetInput: false,
			placeholder: `${this.$t('выберите город')}`
		};
	},
	components: {
		'vue-autocomplete': require('../../../../Autocomplete/index.vue').default
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getByIndex: 'search/state2/getByIndex',
			cities: 'shared/cities/l10n',
			suggests: 'shared/locations/suggestsCity',
			city_: 'shared/cities/city'
		}),
		postfix: function () {
			return `city-${this.index}`;
		},
		value: function () {
			return this.getByIndex({
				name: 'locations',
				index: this.index
			}) || {};
		},
		region: function () {
			return Number(this.value.region) || 0;
		},
		city: {
			get: function () {
				return Number(this.value.city) || 0;
			},
			set: function (city) {
				city = Number(city);
				let value = {
					region: this.region,
					city
				};
				if (!city) {
					value = {
						region: this.region
					};
				}
				this.addByIndex({
					name: 'locations',
					index: this.index,
					value
				});
			}
		},
		optionsList: function () {
			let options = [];
			if (this.region) {
				options.push({
					options: Object.values(this.cities)
						.filter(item => item.state === this.region)
						.sort((a, b) => a.name.localeCompare(b.name, 'en'))
						.map(item => {
							return {
								value: item.value,
								label: item.name
							};
						})
				});
			} else {
				options.push({
					options: [],
					label: `${this.$t('Сначала выберите регион')}`
				});
			}
			return options;
		},
		suggestsList() {
			return [{
				options:
					this.suggests
						.filter(item => {
							return this.city_(item.value).state === this.region;
						})
						.map(item => {
							return {
								value: item.value,
								label: item.name
							};
						})
			}];
		},
		options() {
			return this.mode === 'list' ? this.optionsList : this.suggestsList;
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/cities/fetch',
			suggest: 'shared/locations/suggestCity'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		init() {
			this.fetch();
		},
		selectAutocomplite(value) {
			this.city = Number(value);
			this.mode = 'list';
		},
		inputAutocomplite(value) {
			if (value) {
				this.mode = 'autocomplete';
				this.suggest(value);
			} else {
				this.mode = 'list';
			}
		},
		escAutocomplite() {
			this.mode = 'list';
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		}
	},
};
