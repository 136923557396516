export default {
	messages: {
		ru: {
			'Найдено': 'Найдено',
			'авто': 'авто',
			'Поиск': 'Поиск'
		},
		uk: {
			'Найдено': 'Знайдено',
			'авто': 'авто',
			'Поиск': 'Пошук'
		},
	}
};
