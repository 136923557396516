import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Sold',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getSold: 'search/state2/getSold'
		}),
		value: {
			get: function () {
				return this.getSold;
			},
			set: function (value) {
				this.setSold(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setSold: 'search/state2/setSold'
		}),
	}
};
