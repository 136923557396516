module.exports = {
  agroRia: {
    origin: 'https://agro.ria.com',
    langPrefixNewAuto: true,
  },
  autoRia: {
    origin: 'https://auto.ria.com',
    langPrefixNewAuto: false,
  },
};
