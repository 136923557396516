import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Custom',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getIndices: 'search/state2/getIndices',
			getCustom: 'search/state2/getCustom'
		}),
		custom: {
			get() {
				return this.getCustom;
			},
			set(value) {
				this.setCustom(value);
			}
		},
		indexName: {
			get: function () {
				return this.getIndices;
			},
			set: function (value) {
				this.setIndices(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setIndices: 'search/state2/setIndices',
			setCustom: 'search/state2/setCustom'
		}),
	}
};
