export default {
	messages: {
		ru: {
			'Регион': 'Регион',
			'Ещё': 'Ещё',
			'регион': 'регион | региона | регионов'
		},
		uk: {
			'Регион': 'Регіон',
			'Ещё': 'Ще',
			'регіон': 'регіон | регіону | регіонів'
		},
	}
};
