export default {
	messages: {
		ru: {
			'Показывать': 'Показывать',
			'По': 'По'
		},
		uk: {
			'Показывать': 'Показувати',
			'По': 'По'
		},
	}
};
