import { mapGetters } from 'vuex';

export default {
  name: 'SubscriptionPopup',
  props: {
    popupType: String,
    channel: String,
    count: Number,
  },
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
  },
  methods: {
    cancel() {
      this.$emit('closePopup');
    },
    signAnyway() {
      this.$emit('signAnyway', { target: this.channel });
    },
    capitalize(text) {
      if (typeof text === 'string') {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
      return text;
    },
  },
  computed: {
    ...mapGetters({
      getSearchQuery: 'search/state2/getSearchQuery',
      userData: 'Common/userData',
    }),
    userEmail() {
      return this.userData.userEmail || '';
    },
  },
  i18n: {
    messages: {
      uk: {
        'Поздравляем!': 'Вітаємо!',
        'Вы подписались на поиск авто': 'Ви підписались на пошук авто',
        'Смотреть мои подписки': 'Дивитись мої підписки',
        'Все изменения сохранены, удачного поиска': 'Всі зміни збережені, вдалого пошуку',
        'Уточните поиск!': 'Уточніть пошук!',
        'По вашим параметрам': 'За вашим параметрам перевищено ліміт авто на добу. Будь ласка відредагуйте параметри пошуку',
        'Редактировать параметры': 'Редагувати параметри',
        'У вас уже есть подписка': 'У вас вже є підписка',
        'Если вы хотите создадите новую, то старая удалится': 'Якщо ви створите нову, то попередня видалиться',
        'Все равно создать': 'Все одно створити',
        'Выбрать другой канал': 'Обрати інший канал',
        'Подписка на поиск рассчитана на 100 предложений в сутки. По вашему параметрам на AUTO.RIA поступает в среднем': 'Підписка на пошук розрахована на 100 пропозицій на добу. За вашими параметрами на AUTO.RIA надходить у середньому',
        'предложений в день': 'пропозицій в день',
        'Пожалуйста, уточните критерии поиска.': 'Будь ласка, уточніть критерії пошуку.',
        'Подписка не создана': 'Підписка не створена',
        'Укажите в своей анкете e-mail, на который хотели бы получать свежие предложения': 'Вкажіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції',
        Указать: 'Вказати',
        Подтвердить: 'Підтвердити',
        'Теперь вы будете получать на': 'Тепер ви будете отримувати на',
        'все свежедобавленные авто': 'всі свіжододані авто',
        'Вы уже подписаны на данный поиск, все указанные параметры остались без изменений': 'Ви вже підписані на даний пошук, всі вказані параметри лишились без змін',
        'Подписка отредактирована': 'Підписка відредагована',
        'Подписка сохранена': 'Підписка відредагована',
        'Подтвердите в своей анкете e-mail, на который хотели бы получать свежие предложения': 'Підтвердіть у своїй анкеті e-mail, на який хотіли б отримувати свіжі пропозиції',
        'Слишком много параметров. Чтобы получить свежие предложения, уменьшите количество критериев': 'Надто багато параметрів. Щоб отримувати свіжі пропозиції, зменшіть кількість критеріїв',
      },
      ru: {
        'Поздравляем!': 'Поздравляем!',
        'Вы подписались на поиск авто': 'Вы подписались на поиск авто',
        'Смотреть мои подписки': 'Смотреть мои подписки',
        'Все изменения сохранены, удачного поиска': 'Все изменения сохранены, удачного поиска',
        'Уточните поиск!': 'Уточните поиск!',
        'По вашим параметрам': 'По вашим параметрам превышен лимит авто в сутки. Пожалуйста отредактируйте параметры поиска',
        'Редактировать параметры': 'Редактировать параметры',
        'У вас уже есть подписка': 'У вас уже есть подписка',
        'Если вы хотите создадите новую, то старая удалится': 'Если вы хотите создадите новую, то старая удалится',
        'Все равно создать': 'Все равно создать',
        'Выбрать другой канал': 'Выбрать другой канал',
        'Подписка на поиск рассчитана на 100 предложений в сутки. По вашему параметрам на AUTO.RIA поступает в среднем': 'Подписка на поиск рассчитана на 100 предложений в сутки. По вашему параметрам на AUTO.RIA поступает в среднем',
        'предложений в день': 'предложений в день',
        'Пожалуйста, уточните критерии поиска.': 'Пожалуйста, уточните критерии поиска.',
        'Подписка не создана': 'Подписка не создана',
        'Укажите в своей анкете e-mail, на который хотели бы получать свежие предложения': 'Укажите в своей анкете e-mail, на который хотели бы получать свежие предложения',
        Указать: 'Указать',
        Подтвердить: 'Подтвердить',
        'Теперь вы будете получать на': 'Теперь вы будете получать на',
        'все свежедобавленные авто': 'все свежедобавленные авто',
        'Вы уже подписаны на данный поиск, все указанные параметры остались без изменений': 'Вы уже подписаны на данный поиск, все указанные параметры остались без изменений',
        'Подписка отредактирована': 'Подписка отредактирована',
        'Подписка сохранена': 'Подписка сохранена',
        'Подтвердите в своей анкете e-mail, на который хотели бы получать свежие предложения': 'Подтвердите в своей анкете e-mail, на который хотели бы получать свежие предложения',
        'Слишком много параметров. Чтобы получить свежие предложения, уменьшите количество критериев': 'Слишком много параметров. Чтобы получить свежие предложения, уменьшите количество критериев',
      },
    },
  },
};
