export default {
	messages: {
		ru: {
			'Я ищу город ...': 'Я ищу город ...'
		},
		uk: {
			'Я ищу город ...': 'Я шукаю місто ...'
		},
	}
};
