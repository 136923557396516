import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'CountryOrigin',
	i18n: require('./i18n').default,
	components: {
		'vue-row': require('./Row/index.vue').default
	},
	computed: {
		...mapGetters({
			getCountryOrigin: 'search/state2/getCountryOrigin0'
		}),
		rows() {
			let rows = [];
			this.getCountryOrigin.forEach((item, index) => {
				if (item.not) {
					rows.push(index);
				}
			});
			if (!rows.length) {
				let index = this.getCountryOrigin.length;
				this.addByIndex({
					name: 'country.origin',
					index: index,
					value: {
						not: true
					}
				});
				rows = [index];
			}
			return rows;
		}
	},
	methods: {
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		add() {
			let index = this.getCountryOrigin.length;
			this.addByIndex({
				name: 'country.origin',
				index: index,
				value: {
					not: true
				}
			});
		}
	}
};
