export default {
	name: 'State',
	i18n: require('./i18n').default,
	components: {
		'vue-paint': require('./Paint/index.vue').default,
		'vue-technical': require('./Technical/index.vue').default,
		'vue-damage-v2': require('./Damage-v2/index.vue').default,
		'vue-abroad': require('./Abroad/index.vue').default,
		'vue-custom': require('./Custom/index.vue').default,
		'vue-country-import-usa': require('./CountryImportUSA/index.vue').default,
		'vue-credit': require('./Credit/index.vue').default,
		'vue-confiscated': require('./Confiscated/index.vue').default,
		'vue-spare-parts': require('./SpareParts/index.vue').default,
		'vue-seller-type': require('./SellerType/index.vue').default,
		'vue-country-import': require('./CountryImport/index.vue').default,
		'vue-options': require('./Options/index.vue').default,
	}
};
