export default function getDeviceOs() {
	let deviceOs = '';
	const huaweiRegEx = /huawei|(nexus\s6p|vog-l29|ane-lx1|INE-LX1|eml-l29|ele-l29)|android.+(bah2?-a?[lw]\d{2})|(MediaPad|MediaPad 7 Youth|IDEOS S7|S7-201c|S7-202u|S7-101|S7-103|S7-104|S7-105|S7-106|S7-201|S7-Slim|M2-A01L|BAH-L09|BAH-W09|AGS-L09|CMR-AL19)/i;

	if( navigator ){
		const userAgentOs = navigator.userAgent;
		switch (true) {
			case (huaweiRegEx.test(userAgentOs)):
				deviceOs = 'huawei';
				break;
			case (/Android/i.test(userAgentOs)):
				deviceOs = 'android';
				break;
			case (/iPhone|iPad|iPod|Mac/i.test(userAgentOs)):
				deviceOs = 'ios';
				break;
			default:
				deviceOs = '';
				break;
		}
	}
	return deviceOs;
}

