import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Part',
	props: ['id'],
	init({store}) {
		return store.dispatch('shared/locations/fetchZones');
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getValue: 'search/state2/getValue',
			zonel10n: 'shared/locations/zonel10n'
		}),
		values: function () {
			return this.getValue({
				name: 'locations'
			}).map(item => item ? item : {region: undefined});
		},
		regionValues: function () {
			return this.values.map(item => item.region);
		},
		zone: function () {
			return this.zonel10n(this.id);
		},
		regions: function () {
			let values = this.regionValues;
			return this.zone.states.map(item => {
				item.checked = values.indexOf(item.value) >= 0;
				return item;
			});
		}
	},
	watch: {
		langId() {
			this.fetchZones();
		}
	},
	methods: {
		...mapActions({
			fetchZones: 'shared/locations/fetchZones'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex',
			resetByIndex: 'search/state2/resetByIndex'
		}),
		setByZone: function () {
			let hash = this.regions.reduce(function (hash, item) {
				hash[item.value] = true;
				return hash;
			}, {});
			for (let value of this.values) {
				if (hash.hasOwnProperty(value.region)) {
					hash[value.region] = false;
				}
			}
			for (let region of this.regions) {
				if (hash[region.value] === !!Object.values(hash).filter(Boolean).length) {
					this.toggleChecked(region);
				}
			}
		},
		toggleChecked(checkbox) {
			checkbox.checked = !checkbox.checked;
			if (checkbox.checked) {
				let index = this.values.length;
				for (let i = 0; i < this.values.length; i++) {
					if (!this.values[i].region) {
						index = i;
					}
				}
				this.addByIndex({
					name: 'locations',
					index,
					value: {
						region: checkbox.value
					}
				});
			} else {
				for (let i = 0; i < this.values.length; i++) {
					if (this.values[i].region === checkbox.value) {
						this.resetByIndex({
							name: 'locations',
							index: i
						});
					}
				}
			}
		}
	},
};
