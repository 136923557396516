import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'PhotoVideo',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			getPhoto: 'search/state2/getPhoto',
			getVideo: 'search/state2/getVideo'
		}),
		photo: {
			get: function () {
				return this.getPhoto;
			},
			set: function (value) {
				this.setPhoto(value);
			}
		},
		video: {
			get: function () {
				return this.getVideo;
			},
			set: function (value) {
				this.setVideo(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setPhoto: 'search/state2/setPhoto',
			setVideo: 'search/state2/setVideo'
		}),
	}
};
