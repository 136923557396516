import { mapActions, mapGetters } from 'vuex';

const { get } = require('../../../helpers/cookie');

export default {
  name: 'Color',
  i18n: require('./i18n').default,
  data() {
    return {
      isOpen: false,
      hasTestCookie: false,
    };
  },
  init({ store }) {
    const category = store.getters['search/state2/getCategory'] || 1;
    return Promise.all([
      store.dispatch('shared/colors/fetch'),
      store.dispatch('shared/colorTypes/fetch', { category }),
    ]);
  },
  computed: {
    ...mapGetters({
      colors: 'shared/colors/l10n',
      langId: 'lang/id',
      getColor: 'search/state2/getColor',
      getColorType: 'search/state2/getColorType',
      getCategory: 'search/state2/getCategory',
      colorTypes: 'shared/colorTypes/colorTypes',
    }),
    category() {
      return this.getCategory || 1;
    },
    checkboxes() {
      return Object.values(this.colors).map((color) => {
        color.checked = this.getColor.color.indexOf(color.value) >= 0;
        return color;
      });
    },
    colorTypeCheckboxes() {
      return this.colorTypes({ category: this.category }).map((type) => {
        type.checked = this.getColorType.indexOf(type.value) >= 0;
        return type;
      });
    },
    metallic: {
      get() {
        return this.getColor.metallic;
      },
      set(value) {
        this.setColor({ metallic: Number(value) });
      },
    },
  },
  watch: {
    langId() {
      this.fetchColors();
    },
  },
  methods: {
    ...mapActions({
      fetchColors: 'shared/colors/fetch',
      setColor: 'search/state2/setColor',
      fetchColorTypes: 'shared/colorTypes/fetch',
      setColorType: 'search/state2/setColorType',
    }),
    toggleChecked(checkbox) {
      this.setColor({ color: checkbox.value, set: checkbox.checked });
    },
    toggleColorTypeChecked(checkbox) {
      this.setColorType({ value: checkbox.value, set: checkbox.checked });
    },
    init() {
      this.fetchColorTypes({ category: this.category });
    },
  },
  mounted() {
    this.hasTestCookie = get('testColorTypes') || false;
    this.init();
    this.fetchColors();
  },
};
