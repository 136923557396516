import { mapActions, mapGetters } from 'vuex';

export default {
	init({ store }) {
		const category = store.getters['search/state2/getCategory'] || 1;
		return Promise
			.all([
				store.dispatch('shared/options/fetch', { category }),
				store.dispatch('shared/options/additionalOptions', { category }),
			]);
	},
	name: 'Options',
	components: {
		'vue-row': require('./Row/index.vue').default,
		'VueSelect': require('./VueSelect/index.vue').default,
	},
	computed: {
		...mapGetters({
			options: 'shared/options/options',
			additionalOptions: 'shared/options/additionalOptions',
			getCategory: 'search/state2/getCategory',
		}),
		category() {
			return this.getCategory || 1;
		}
	},
	watch: {
		category() {
			this.init();
		},
		langId() {
			this.init();
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		...mapActions({
			fetchOptions: 'shared/options/fetch',
			fetchAdditionalOptions: 'shared/options/additionalOptions'
		}),
		init() {
			const data = { category: this.category };
			this.fetchOptions(data);
			this.fetchAdditionalOptions(data);
		}
	},
	i18n: {
		messages: {
			ru: {
				'Додаткові опції': 'Дополнительные опции',
				'Состояние': 'Состояние',
				'Безопасность': 'Безопасность',
				'Комфорт': 'Салон и комфорт',
				'Мультимедиа': 'Мультимедиа',
				'Система помощи при парковке': 'Система помощи при парковке',
				'Оптика': 'Оптика',
				'Подушка безопасности': 'Подушка безопасности',
				'Салон и кузов': 'Кузов',
				'Прочее': 'Дополнительное оборудование'
			},
			uk: {
				'Додаткові опції': 'Додаткові опції',
				'Состояние': 'Стан',
				'Безопасность': 'Безпека',
				'Комфорт': 'Салон та комфорт',
				'Мультимедиа': 'Мультимедіа',
				'Система помощи при парковке': 'Система допомоги при паркувані',
				'Оптика': 'Оптика',
				'Подушка безопасности': 'Подушка безпеки',
				'Салон и кузов': 'Кузов',
				'Прочее': 'Додаткове обладнання'
			}
		}
	}
};
