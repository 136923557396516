import { mapGetters, mapActions } from 'vuex';
import getDeviceOs from '../../../helpers/getDeviceOs';
import Popups from './Popups/index.vue';
import { get, set } from '../../../helpers/cookie';

export default {
  name: 'Subscribe',
  i18n: require('./i18n').default,
  data() {
    return {
      email: '',
      popupType: undefined,
      botsInfo: [],
      channel: undefined,
    };
  },
  mounted() {
    this.checkSubscribeBot()
      .then((data) => {
        this.botsInfo = data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  components: {
    Popups,
  },
  watch: {
    getSearchQuery() {
      this.search(this.getSearchQuery);
    },
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      getSearchQuery: 'search/state2/getSearchQuery',
      channelId: 'search/state2/channelId',
      editSubscribeId: 'search/state2/editSubscribe',
      count: 'search/result/count',
    }),
    showModal() {
      return _TARGET_ === 'client' && !get('advanced_search_subscribe_modal');
    },
  },
  methods: {
    ...mapActions({
      createHash: 'search/state2/createHash',
      createEmailSub: 'search/state2/createEmailSub',
      editSub: 'search/state2/editSub',
      search: 'search/result/search',
      checkSubscribeBot: 'search/state2/checkSubscribeBot',
    }),
    closeModal() {
      set('advanced_search_subscribe_modal', 1, { expires: 2592000 });
    },
    closePopup() {
      this.popupType = undefined;
    },
    sendGa(platform) {
      this.gaEvent('New_Advanced_Search', platform, 'bu_Social_networking_service');
    },
    beforeSubscribe(target) {
      if (target === 'telegram' && this.botsInfo[target]?.count) {
        this.channel = target;
        this.popupType = 'hasAlready';
        return;
      }

      this.createNewSubscribe(target);
    },
    signAnyway(data) {
      this.popupType = undefined;
      this.createNewSubscribe(data.target);
    },
    createNewSubscribe(target) {
      const searchQuery = this.getSearchQuery;

      const sqp = Object.keys(searchQuery).map((param) => (param !== 'newSubscribe' ? `${param}=${searchQuery[param]}` : '')).join('&');

      const payload = {
        searchQuery: sqp,
        mailing_email: this.userData.userEmail,
      };

      let sq = `https://auto.ria.com/search/?${sqp}`;

      if (this.userData?.userId) {
        sq += `&subscriberId=${this.userData.userId}`;
      }

      if (this.userData?.webClientId) {
        sq += `&webId=${this.userData.webClientId}`;
      }

      sq = encodeURIComponent(sq);

      this.channel = target;

      switch (target) {
        case 'telegram':
          this.createHash(sq)
            .then((data) => {
              if (data.key && !data.error) {
                this.sendGa('Telegram');
                this.popupType = 'success';
                window.open(`https://telegram.me/AutoRiaSearchBot?start=${data.key}`, '_blank');
              }
            });
          break;
        case 'facebook':
          this.popupType = 'success';
          this.sendGa('Facebook');
          window.open(`http://m.me/AutoRiaSearchBot/?ref=${sq}`, '_blank');
          break;
        case 'application':
          this.createHash(sq)
            .then((data) => {
              if (data.key && !data.error) {
                let url = '';
                const windowReference = window.open();
                this.sendGa('App');
                this.popupType = 'success';
                switch (getDeviceOs()) {
                  case 'android':
                  case 'huawei':
                    url = `https://autoriaapp.page.link?apn=com.ria.auto&link=https%3A%2F%2Fauto.ria.com%2FdynamicLink%2F%3Ftarget%3DsubscribeOnSearch%26SearchURLHashMD5%3D${data.key}`;
                    window.open(url, '_blank');
                    break;
                  case 'ios':
                    url = `https://autoriaios.page.link/?link=https%3A%2F%2Fauto%2Eria%2Ecom%2F%3Ftarget%3DsubscribeOnSearch%26SearchURLHashMD5%3D${data.key}&ibi=auto%2Eria&isi=1083363297`;
                    windowReference.location = url;
                    break;
                  default:
                    url = `https://autoriaapp.page.link?apn=com.ria.auto&link=https%3A%2F%2Fauto.ria.com%2FdynamicLink%2F%3Ftarget%3DsubscribeOnSearch%26SearchURLHashMD5%3D${data.key}`;
                    window.open(url, '_blank');
                    break;
                }
              }
            });
          break;
        case 'email':
          if (!this.userData?.emailVerified) {
            this.popupType = 'emailVerified';
          } else if (this.userData?.userEmail) {
            this.createEmailSub(payload)
              .then((data) => {
                if (!data.error) {
                  this.sendGa('Mail');
                  this.popupType = 'successEmail';
                } else {
                  this.popupType = 'selectParam';
                }
              });
          } else {
            this.popupType = 'addEmail';
          }
          break;
        default:
          break;
      }
    },
    editSubscribe() {
      const searchQuery = this.getSearchQuery;
      const sq = Object.keys(searchQuery).map((param) => (param !== 'editSubscribe' || param !== 'channelId' ? `${param}=${searchQuery[param]}` : '')).join('&');
      // https://auto-ria.atlassian.net/browse/VCWEB-811?atlOrigin=eyJpIjoiNDBhZGNjYWU3NjdkNGIwZmE2NTkxOTdkNzI1OTFkNzYiLCJwIjoiaiJ9
      if (sq?.length > 1400) {
        this.popupType = 'subStringOverSize';
        return;
      }
      const payload = {
        channel: this.channelId,
        id: this.editSubscribeId,
        searchString: sq,
      };

      this.editSub(payload)
        .then((data) => {
          if (!data.error) {
            this.popupType = 'save';
          }
        });
    },
  },
};
