export default {
  messages: {
    ru: {
      'Экологический стандарт': 'Экологический стандарт',
    },
    uk: {
      'Экологический стандарт': 'Екологічний стандарт',
    },
  },
};
