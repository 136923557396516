import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'SpareParts',
	i18n: require('./i18n').default,
	data: function () {
		return {};
	},
	computed: {
		...mapGetters({
			getSpareParts: 'search/state2/getSpareParts'
		}),
		spareParts: {
			get: function () {
				return this.getSpareParts;
			},
			set: function (value) {
				this.setSpareParts(value);
			}
		},
	},
	methods: {
		...mapMutations({
			setSpareParts: 'search/state2/setSpareParts'
		}),
	}
};
