export default {
	messages: {
		ru: {
			'Тип обмена': 'Тип обмена',
			'Любой': 'Любой',
			'Моя доплата': 'Моя доплата',
			'Хочу доплату': 'Хочу доплату',
			'Равноценный обмен': 'Равноценный обмен'
		},
		uk: {
			'Тип обмена': 'Тип обміну',
			'Модель': 'Модель',
			'Любой': 'Будь-який',
			'Моя доплата': 'Моя доплата',
			'Хочу доплату': 'Бажаю доплату',
			'Равноценный обмен': 'Рівноцінний обмін'
		},
	}
};
