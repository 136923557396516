import {mapGetters, mapMutations} from 'vuex';

export default {
	name: 'Engine',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getEngine: 'search/state2/getEngine'
		}),
		gte: {
			get() {
				return this.getEngine.gte;
			},
			set(value) {
				let payload = this.getEngine;
				payload.gte = value;
				this.setEngine(payload);
			}
		},
		lte: {
			get() {
				return this.getEngine.lte;
			},
			set(value) {
				let payload = this.getEngine;
				payload.lte = value;
				this.setEngine(payload);
			}
		}
	},
	methods: {
		...mapMutations({
			setEngine: 'search/state2/setEngine'
		})
	}
};
