export default {
	messages: {
		ru: {
			'Вид техники': 'Вид техники',
			'Все виды техники': 'Все виды техники'
		},
		uk: {
			'Вид техники': 'Вид техніки',
			'Все виды техники': 'Всі види техніки'
		},
	}
};
