import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
	name: 'Country',
	i18n: require('./i18n').default,
	init({store}) {
		return store.dispatch('shared/countries/fetch');
	},
	computed: {
		...mapGetters({
			getCountryOrigin: 'search/state2/getCountryOrigin0',
			countries: 'shared/countries/l10n',
			langId: 'lang/id',
			country: 'shared/countries/country'
		}),
		countriesOptionsList() {
			return [
				...Object.assign([], s).splice(0, 10).map(country => this.country(country.id)),
				...Object.values(this.countries)
					.sort((a, b) => a.name.localeCompare(b.name, 'en'))
			];
		},
		selected: {
			get: function () {
				return this.getCountryOrigin[this.index] ? this.getCountryOrigin[this.index].id : 0;
			},
			set: function (id) {
				this.setCountryOrigin({
					index: this.index,
					value: {
						id
					}
				});
			}
		},
		rows() {
			let rows = [];
			this.getCountryOrigin.forEach((item, index) => {
				if (!item.not) {
					rows.push(index);
				}
			});
			if (!rows.length) {
				let index = this.getCountryOrigin.length;
				this.addByIndex({
					name: 'country.origin',
					index: index,
					value: {
						id: 0
					}
				});
				rows = [index];
			}
			return rows;
		},
		index() {
			return this.rows[0];
		}
	},
	watch: {
		langId() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetch: 'shared/countries/fetch',
			setCountryOrigin: 'search/state2/setCountryOrigin0'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex'
		}),
		init() {
			this.fetch();
		}
	},
	mounted() {
		this.init();
	}
};

let s = [
	{ id: '276', cnt: 20402 },
	{ id: '392', cnt: 15443 },
	{ id: '250', cnt: 6796 },
	{ id: '840', cnt: 6326 },
	{ id: '408', cnt: 5676 },
	{ id: '203', cnt: 5311 },
	{ id: '804', cnt: 3888 },
	{ id: '380', cnt: 3825 },
	{ id: '752', cnt: 3623 },
	{ id: '158', cnt: 3411 },
	{ id: '826', cnt: 3131 },
	{ id: '643', cnt: 2754 },
	{ id: '724', cnt: 2521 },
	{ id: '616', cnt: 2275 },
	{ id: '40', cnt: 1304 },
	{ id: '642', cnt: 1063 },
	{ id: '428', cnt: 943 },
	{ id: '458', cnt: 555 },
	{ id: '112', cnt: 542 },
	{ id: '356', cnt: 482 },
	{ id: '364', cnt: 468 },
	{ id: '528', cnt: 463 },
	{ id: '860', cnt: 274 },
	{ id: '246', cnt: 220 },
	{ id: '792', cnt: 152 },
	{ id: '348', cnt: 134 },
	{ id: '100', cnt: 122 },
	{ id: '703', cnt: 118 },
	{ id: '124', cnt: 114 },
	{ id: '440', cnt: 98 },
	{ id: '56', cnt: 86 },
	{ id: '705', cnt: 54 },
	{ id: '578', cnt: 49 },
	{ id: '233', cnt: 40 },
	{ id: '498', cnt: 38 },
	{ id: '32', cnt: 34 },
	{ id: '442', cnt: 28 },
	{ id: '620', cnt: 20 },
	{ id: '688', cnt: 8 },
	{ id: '398', cnt: 7 },
	{ id: '156', cnt: 6 },
	{ id: '756', cnt: 4 },
	{ id: '900', cnt: 2 },
	{ id: '901', cnt: 2 },
	{ id: '208', cnt: 1 } ];
