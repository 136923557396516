export default {
	messages: {
		ru: {
			'Только с фото': 'Только с фото',
			'Только с видео': 'Только с видео'
		},
		uk: {
			'Только с фото': 'Тільки з фото',
			'Только с видео': 'Тільки з відео'
		},
	}
};
