import isChildElement from '../../../helpers/isChildElement';
import WindowEventWatcher from '../../../helpers/WindowEventWatcher';

let watcher = new WindowEventWatcher('click');

export default {
	name: 'MultiAutocomplete',
	data() {
		return {
			isOptionsHidden: true,
			inputText: ''
		};
	},
	props: ['selected', 'options', 'postfix', 'placeholder', 'title'],
	metaInfo() {
		return {
			bodyAttrs: {
				'class': this.mobilePopupActive ? 'autocomplete-popup' : ''
			}
		};
	},
	mounted() {
		let node = this.$refs.pseudoselect;
		const hide = function ({target} = {}) {
			if (target !== node && !isChildElement(node, target)) {
				this.esc();
			}
		}.bind(this);
		watcher.subscribe(node, hide);
		window.addEventListener('popstate', this.popstate);
	},
	watch: {
		isOptionsHidden(value) {
			if (this.isMobile && value) {
				history.go(1);
			}
		}
	},
	computed: {
		placeholder_() {
			if (this.isOptionsHidden) {
				return this.title ? this.title : this.placeholder;
			} else {
				return this.placeholder;
			}
		},
		mobilePopupActive() {
			return this.isMobile && !this.isOptionsHidden;
		},
		selectedHash() {
			return this.selected.reduce((o, item) => {
				o[item] = true;
				return o;
			}, {});
		},
		optionsList() {
			let i = 0;
			return Array.isArray(this.options) ?
				this.options.map(({label, options} = {}) => {
					const optionsNew = options.map(({value, label} = {}) => ({value, label, index: i++}));
					return {label, options: optionsNew};
				}) : [];
		},
		optionsListFlat() {
			return this.optionsList.reduce((a, {options}) => {
				a = Array.isArray(options) ? a.concat(options) : a;
				return a;
			}, []);
		}
	},
	methods: {
		popstate(event) {
			this.hideOptionsList();
			event.preventDefault();
		},
		hideOptionsList() {
			this.isOptionsHidden = true;
			this.$emit('hide');
		},
		input(event) {
			const {target: {value} = {}} = event;
			this.$emit('input', value);
		},
		keydown(event) {
			switch (event.keyCode) {
				case 9: { // tab
					this.hideOptionsList();
					return;
				}
				case 27: { // esc
					this.esc();
					return;
				}
			}
		},
		showOptionsList() {
			this.isOptionsHidden = false;
			this.$emit('show');
		},
		esc() {
			this.inputText = '';
			this.hideOptionsList();
		},
		select(value) {
			this.$emit('select', value);
		},
		drop() {
			this.inputText = '';
			this.$emit('input', '');
		}
	},
	beforeDestroy() {
		watcher.unsubscribe(this.$refs.pseudoselect);
		window.removeEventListener('popstate', this.popstate);
	}
};
