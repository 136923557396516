export default {
	messages: {
		ru: {
			'Тип кузова': 'Тип кузова',
			'Другие': 'Другие',
			'тип': 'тип | типы',
			'кузова': 'кузова'
		},
		uk: {
			'Тип кузова': 'Тип кузова',
			'Другие': 'Інші',
			'тип': 'тип | типи',
			'кузова': 'кузову'
		},
	}
};
