export default {
	messages: {
		ru: {
			'Тип транспорта': 'Тип транспорта',
			'Все типы транспорта': 'Все типы транспорта'
		},
		uk: {
			'Тип транспорта': 'Тип транспорту',
			'Все типы транспорта': 'Всі типи транспорту'
		},
	}
};
