export default {
	messages: {
		ru: {
			'Выберите страну': 'Выберите страну'
		},
		uk: {
			'Выберите страну': 'Оберіть країну'
		},
	}
};
