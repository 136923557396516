import {mapGetters, mapActions, mapMutations} from 'vuex';
import getFromSearchHistory from '../../../../../helpers/getFromSearchHistory';
import getGenerationKey from "../../../../../store/shared/generation/helpers/getGenerationKey";

export default {
	name: 'Model',
	i18n: require('./i18n').default,
	components: {
		Autocomplete: require('../../../MultyAutocomplete/index.vue').default
	},
	props: ['indices', 'blockIndex', 'exclude', 'years'],
	data: function () {
		return {
			mode: 'list',
			isOptionsHidden: true,
			suggesting: '',
			selectedName: '',
			resetInput: false,
			mounted: false,
			placeholder: `${this.$t('Выберите модель')}`,
			selected_: []
		};
	},
	methods: {
		...mapActions({
			fetch: 'shared/models/fetchByBrand',
			fetchPopular: 'shared/models/fetchPopular',
			suggest: 'shared/models/suggest',
			fetchGeneration: 'shared/generation/fetchGeneration',
			deleteGeneration: 'shared/generation/deleteGeneration',
			fetchAllModificationsByModel: 'shared/modification/fetchAllModificationsByModel',
			deleteModificationList: 'shared/modification/deleteModificationList'
		}),
		...mapMutations({
			addByIndex: 'search/state2/addByIndex',
			delByIndex: 'search/state2/delByIndex'
		}),
		init: function () {
			if (this.brand) {
				this.fetch({category: this.category, brand: this.brand});
				if (this.category) {
					this.fetchPopular({category: this.category, brand: this.brand});
				}
			}
		},
		select(value) {
			value = Number(value);
			// операції з полем модель
			if (this.selected.indexOf(value) >= 0) {
				//спочатку виконуємо операції з залежними полями потім виконуємо всі маніпуляції з основним
				// видалення модифікацій
				this.deleteModificationList({
					blockIndex: this.blockIndex,
					brandId: this.brand,
					modelId: value,
					exclude: this.exclude
				})
				//видалення поколінь до видалення моделі(якщо це потрібно)
				this.deleteGeneration({
					blockIndex: this.blockIndex,
					brandId: this.brand,
					modelId: value,
					exclude: this.exclude
				})
				//	видалити
				if (this.selected.length > 1) {
					// якщо кілька, то грохнути її
					for (let index of this.indices) {
						let item = this.getByIndex({
							name: 'series',
							index
						}) || {};
						if (Number(item.model) === value) {
							this.delByIndex({
								name: 'series',
								index
							});
						}
					}
				} else {
					// якщо це один запис, то модель скинути
					for (let index of this.indices) {
						let item = this.getByIndex({
							name: 'series',
							index
						}) || {};
						if (Number(item.model) === value) {
							if(item.hasOwnProperty('modification')) delete item.modification;
							if(item.hasOwnProperty('generation')) delete item.generation;
							delete item.model;
							this.addByIndex({
								name: 'series',
								index,
								value: item
							});
							break;
						}
					}
				}
			} else {
				//	засетапити
				let item = this.getByIndex({
					name: 'series',
					index: this.indices[0]
				}) || {};
				item = Object.assign({}, item);
				if(item.hasOwnProperty('modification')) delete item.modification;
				if(item.hasOwnProperty('generation')) delete item.generation;
				item.model = value;
				let index = this.indices[0];
				if (this.selected.length !== 0) {
					// в іншому випадку створити новий запис
					index = this.series.length;
				}
				this.addByIndex({
					name: 'series',
					index,
					value: item
				});
				const { blockIndex , brand: brandId, years, exclude } = this;
				this.fetchGeneration({blockIndex , brandId, modelId: value, years, exclude });
				this.fetchAllModificationsByModel({blockIndex , brandId, modelId: value, years, exclude })
			}
		},
		input(value) {
			if (value) {
				this.mode = 'autocomplete';
				this.suggest({text: value, category: this.category, brand: this.brand});
			} else {
				this.mode = 'list';
			}
		},
		hideOptions() {
			this.selected_ = this.selected;
			this.mode = 'list';
		},
		showOptions() {
			this.selected_ = this.selected;
		},
		async fetchGenerationByModel(modelIdArray){
			const { blockIndex, brand: brandId, years, exclude } = this;

			const checkExistGenerationList = modelIdArray.filter(modelId => {
				const blockKey = getGenerationKey({blockIndex, exclude, brandId, modelId })

				return !this.allGenerationsData.hasOwnProperty(blockKey)
			})

			if(!checkExistGenerationList.length) return;
			return await this.fetchGeneration({blockIndex , brandId, modelId: checkExistGenerationList, years, exclude});
		},
		async fetchModificationsByModel(modelIdArray){
			const { blockIndex, brand: brandId, years, exclude } = this;

			await modelIdArray.forEach((modelId) => this.fetchAllModificationsByModel({blockIndex , brandId, modelId, years, exclude }))
		}
	},
	mounted: function () {
		this.mounted = true;
		this.init();
	},
	computed: {
		series: function () {
			return this.getValue({
				name: 'series'
			});
		},
		title() {
			return this.indices.map(index => {
				let {model = 0} = this.getByIndex({
					name: 'series',
					index
				}) || {};
				if (model && this.modelName(model)) {
					return this.modelName(model).name;
				}
			}).filter(Boolean).join(', ');
		},
		selected() {
			return this.indices.map(index => {
				return this.getByIndex({
					name: 'series',
					index
				}) || {};
			}).map(item => item.model).filter(Number);
		},
		...mapGetters({
			langId: 'lang/id',
			getValue: 'search/state2/getValue',
			modelsForBrand: 'shared/models/getByBrand',
			modelsPopular: 'shared/models/getPopular',
			suggests: 'shared/models/suggests',
			getByIndex: 'search/state2/getByIndex',
			modelName: 'shared/models/model',
			allGenerationsData : 'shared/generation/allGenerationsData',
		}),
		models() {
			return this.modelsForBrand(this.brand, this.category) || {};
		},
		postfix: function () {
			return `model-${this.blockIndex}${this.exclude ? '-not' : ''}`;
		},
		category: function () {
			return this.getValue('categories.main.id');
		},
		brand: function () {
			let value = this.groupValue;
			return value.brand || 0;
		},
		model: function () {
			let value = this.groupValue;
			return value.model || 0;
		},
		groupValue: function () {
			return this.getByIndex({
				name: 'series',
				index: this.indices[0]
			}) || {};
		},
		optionsRaw() {
			return Object.values(this.models);
		},
		optionsSearched() {
			if (this.mounted) {
				const idsSearched = [...new Set(getFromSearchHistory('model'))];
				const options = this.optionsRaw;
				return [...new Set([...idsSearched, ...this.selected_])].reduce((result, item) => {
					const optionSearched = options.find(({value}) => value === item);
					if (optionSearched) {
						result.push(Object.assign({}, optionSearched));
					}
					return result;
				}, []).map(item => {
					return {
						value: item.value,
						label: item.name
					};
				});
			} else {
				return [];
			}
		},
		optionsAll() {
			return this.optionsRaw.map(item => {
				return {
					value: item.value,
					label: item.name
				};
			});
		},
		optionsPopular() {
			return this.modelsPopular(this.brand, this.category)
				.map(({value, name: label}) => ({value, label}));
		},
		optionsForBrand() {
			let options = [];
			if (!this.brand) {
				return [{
					options: [],
					label: `${this.$t('Сначала выберите марку')}`
				}];
			}
			if (this.optionsSearched.length) {
				options.push({
					options: this.optionsSearched,
					label: this.$t('Просмотренные')
				});
			}
			if (this.optionsPopular.length) {
				options.push({
					options: this.optionsPopular,
					label: this.$t('Популярные')
				});
			}
			if (this.optionsAll.length) {
				options.push({
					options: this.optionsAll,
					label: `${this.$t('Все модели')}`
				});
			}
			return options;
		},
		suggestsList() {
			return [{
				options: this.suggests.map(item => {
					return {
						value: item.value,
						label: item.name
					};
				})
			}];
		},
		options() {
			return this.mode === 'list' ? this.optionsForBrand : this.suggestsList;
		}
	},
	watch: {
		langId() {
			this.init();
		},
		category() {
			this.init();
		},
		brand(value) {
			if (value) {
				this.fetch({category: this.category, brand: this.brand});
				if (this.category) {
					this.fetchPopular({category: this.category, brand: this.brand});
				}
			}
		},
		selected: {
			async handler(newValue, oldValue){
				if(
					Array.isArray(oldValue) &&
					Array.isArray(newValue) &&
					!oldValue.length &&
					!newValue.length &&
					newValue.length < oldValue.length
				) return;
				await this.fetchGenerationByModel(newValue);
				await this.fetchModificationsByModel(newValue);
			},
			immediate: true
		},
	}
};
