export default {
	messages: {
		ru: {
			'Колесная формула': 'Колесная формула',
			'Любая': 'Любая'
		},
		uk: {
			'Колесная формула': 'Колісна формула',
			'Любая': 'Будь-яка'
		},
	}
};
