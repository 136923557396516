export default {
    name: 'ModelGenerationItem',
    props: {
        blockIndex: {
            type: Number,
            required: true
        },
        id:{
            type: String,
            required: true,
        },
        generation: {
            type: Object,
            required: true
        },
        isChecked: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        yearTo() {
            return this?.generation?.yearTo || '...'
        }
    }
}
