export default {
	messages: {
		ru: {
			'Год': 'Год',
			'от': 'от',
			'до': 'до',
			'Удалить': 'Удалить'
		},
		uk: {
			'Год': 'Рік',
			'от': 'від',
			'до': 'до',
			'Удалить': 'Видалити'
		},
	}
};
