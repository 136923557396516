export default {
	messages: {
		ru: {
			'Результаты поиска': 'Результаты поиска'
		},
		uk: {
			'Результаты поиска': 'Результати пошуку'
		},
	}
};
