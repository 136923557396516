export default {
	messages: {
		ru: {
			'Исключить страну': 'Исключить страну',
			'Ещё страна': 'Ещё страна'
		},
		uk: {
			'Исключить страну': 'Виключити країну',
			'Ещё страна': 'Ще країна'
		},
	}
};
