import {mapActions, mapGetters} from 'vuex';

export default {
	name: 'SearchHistory',
	i18n: require('./i18n').default,
	data: function () {
		return {
			opened: false
		};
	},
	computed: {
		...mapGetters({
			lastSearchesFilled: 'search/history/lastSearchesFilled'
		})
	},
	watch: {
		langId() {
			this.fetchLastSearches({limit: 10});
		}
	},
	methods: {
		...mapActions({
			fetchLastSearches: 'search/history/fetchLastSearches'
		})
	},
	mounted() {
		this.fetchLastSearches({limit: 10});
	}
};
