export default {
	messages: {
		ru: {
			'Тип кузова': 'Тип кузова',
			'Любой': 'Любой'
		},
		uk: {
			'Тип кузова': 'Тип кузова',
			'Любой': 'Будь-який'
		},
	}
};
