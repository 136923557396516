import {mapActions, mapGetters} from 'vuex';

export default {
	init({store}) {
		return store.dispatch('shared/categories/fetch');
	},
	name: 'Category',
	i18n: require('./i18n').default,
	computed: {
		...mapGetters({
			categories: 'shared/categories/l10n',
			getExchangesCategory: 'search/state2/getExchangesCategory',
		}),
		options() {
			return Object.values(this.categories);
		},
		selected: {
			get() {
				return this.getExchangesCategory;
			},
			set(value) {
				this.setExchangesCategory(value);
			}
		}
	},
	watch: {
		langId() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetchCategories: 'shared/categories/fetch',
			setExchangesCategory: 'search/state2/setExchangesCategory'
		}),
		init() {
			this.fetchCategories();
		}
	},
	mounted() {
		this.init();
	}
};
