import {mapGetters} from "vuex";

export default {
	name: 'Mobile',
	props: ['add'],
	data: function() {
		return {
			rows: [0]
		}
	},
	computed: {
		...mapGetters({
			langId: 'lang/id',
			getValue: 'search/state2/getValue'
		}),
		values: function () {
			return this.getValue({
				name: 'locations'
			}).map(item => item && item.region ? item : {});
		}
	},
	components: {
		'vue-row': require('./Row/index.vue').default
	},
	watch: {
		add() {
			this.rows.push(Math.max.apply(null, this.rows) + 1);
		}
	},
	created: function () {
		let rows = [];
		for (let i = 0; i < this.values.length; i++) {
			if (this.values[i] && Number(this.values[i].region)) {
				rows.push(i);
			}
		}
		if (rows.length) {
			this.rows = rows;
		} else {
			this.rows = [0];
		}
	}
};
