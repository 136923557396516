import {mapActions, mapGetters} from "vuex";

export default {
	init({store}) {
		const category = store.getters['search/state2/getCategory'] || 1;
		return store.dispatch('shared/options/fetch', {category});
	},
	name: 'Row',
	props: ['title'],
	computed: {
		...mapGetters({
			options: 'shared/options/options',
			getCategory: 'search/state2/getCategory',
			getOptions: 'search/state2/getOptions',
		}),
		category() {
			return this.getCategory || 1;
		},
		checkboxes() {
			return this.options({category: this.category, type: 'state'})
				.map((option) => {
					option.checked = this.getOptions.indexOf(option.value) >= 0;
					return option;
				});
		}
	},
	watch: {
		category() {
			this.init();
		},
		langId() {
			this.init();
		}
	},
	methods: {
		...mapActions({
			fetchOptions: 'shared/options/fetch',
			setOptions: 'search/state2/setOptions',
		}),
		toggleChecked(checkbox) {
			this.setOptions({value: checkbox.value, set: checkbox.checked});
		},
		init() {
			this.fetchOptions({type: 'state', category: this.category});
		}
	},
	mounted() {
		this.init();
	}
};
