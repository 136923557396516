export default {
	messages: {
		ru: {
			'Топливо': 'Топливо'
		},
		uk: {
			'Топливо': 'Паливо'
		},
	}
};
