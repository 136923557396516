export default {
    messages: {
        uk: {
            'Модифікації': 'Модифікації',
        },
        ru: {
            'Модифікації': 'Модификации',
        },
    }
}
