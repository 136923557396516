export default {
	messages: {
		ru: {
			'Страна производитель': 'Страна производитель',
			'Выберите страну': 'Выберите страну'
		},
		uk: {
			'Страна производитель': 'Країна виробник',
			'Выберите страну': 'Оберіть країну'
		}
	}
};
