export default {
    messages: {
        uk: {
            'Покоління': 'Покоління',
        },
        ru: {
            'Покоління': 'Поколения',
        },
    }
}
