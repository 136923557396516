export default {
  messages: {
    ru: {
      'Радіус пошуку': 'Радиус поиска',
      'Оберіть': 'Выберите',
    },
    uk: {
      'Радіус пошуку': 'Радіус пошуку',
      'Оберіть': 'Оберіть',
    },
  },
};
