import {mapGetters, mapMutations, mapActions} from 'vuex';
const mapSearchType = {
	'auto,order_auto,newauto_search': 'all_search',
	'auto': 'bu_search',
	'order_auto': 'order_search',
};

export default {
	name: 'Exchanges',
	i18n: require('./i18n').default,
	components: {
		'vue-advertisement-exchanges': require('./AdvertisementExchanges/index.vue').default
	},
	computed: {
		...mapGetters({
			getValue: 'search/state2/getValue',
			getAuction: 'search/state2/getAuction',
			getVat: 'search/state2/getVat',
			getInstallment: 'search/state2/getInstallment',
			getExchangeAuto: 'search/state2/getExchangeAuto',
			getIndices: 'search/state2/getIndices',
		}),
		auction: {
			get() {
				return this.getAuction;
			},
			set(value) {
				this.setAuction(value);
			}
		},
		exchangeAuto: {
			get() {
				return this.getExchangeAuto;
			},
			set() {
				this.setExchange(1);
			}
		},
		vat: {
			get() {
				return this.getVat;
			},
			set(value) {
				this.clickVat(value);
				this.setVat(value);
			}
		},
		installment: {
			get() {
				return this.getInstallment;
			},
			set(value) {
				return this.setInstallment(Number(value));
			}
		},
	},
	methods: {
		...mapActions({
			setExchange: 'search/state2/setExchange'
		}),
		...mapMutations({
			setAuction: 'search/state2/setAuction',
			setVat: 'search/state2/setVat',
			setInstallment: 'search/state2/setInstallment',
		}),
		clickVat(vat) {
			if (mapSearchType[this.getIndices]) {
				this.gaEvent('New_Advanced_Search', vat ? 'Setup_NDS' : 'Unsetup_NDS', mapSearchType[this.getIndices]);
			}
		},
	},
};
