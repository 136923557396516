
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'VueSelect',
    props: {
        name: String,
        title: String,
        elements: Array,
    },
    computed: {
        ...mapGetters({
            getValue: 'search/state2/getValue',
        }),
        id() {
            return `${this.name}.id`
        },
        selected: {
            get() {
                return this.getValue(this.id) || 0;
            },
            set(value) {
                return this.setValue({ value: Number(value), name: this.id });
            }
        }
    },
    methods: {
        ...mapMutations({
            setValue: 'search/state2/setValue',
        })
    },
    i18n: {
        messages: {
            ru: {
                'Оберіть': 'Выберите'
            },
            uk: {
                'Оберіть': 'Оберіть',
            }
        }
    }
}
