import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Categories',
  i18n: require('./i18n').default,
  init({ store }) {
    return store.dispatch('shared/categories/fetch');
  },
  computed: {
    ...mapGetters({
      getCategory: 'search/state2/getCategory',
      categories: 'shared/categories/l10n',
      langId: 'lang/id',
    }),
    options() {
      return Object.values(this.categories)
        .sort((a, b) => a.index - b.index);
    },
    selected: {
      get() {
        return this.getCategory;
      },
      set(value) {
        this.setCategory(value);
      },
    },
  },
  watch: {
    langId() {
      this.fetchCategories(this.langId);
    },
  },
  methods: {
    ...mapActions({
      fetchCategories: 'shared/categories/fetch',
      setCategory: 'search/state2/setCategory',
    }),
  },
  mounted() {
    this.fetchCategories();
  },
};
