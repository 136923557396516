import {mapActions, mapGetters} from 'vuex'
import translate from "./i18n";

export default {
    name: "ModificationField",
    components: {
        'select-field-wrapper': require('../../../../Common/SelectFieldWrapper/index.vue').default,
        'modification-list': require('./ModificationList/index.vue').default,
    },
    props: {
        indices: {
            type: Array,
            required: true
        },
        blockIndex: {
            type: Number,
            required: true
        },
        brandId: {
            type: Number | String,
            required: true
        },
        models: {
            type: Array,
            required: true
        },
        exclude: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            getByIndex: 'search/state2/getByIndex',
            getValue: 'search/state2/getValue',
        }),
        ...mapGetters('shared/generation', {
            generationsByIndexBlock: 'generationsByIndexBlock'
        }),
        ...mapGetters('shared/modification', {
            modificationsByIndexBlock: 'modificationsByIndexBlock'
        }),
        generations() {
            return this.generationsByIndexBlock(this.blockIndex, this.exclude, this.brandId);
        },
        modifications() {
            return this.modificationsByIndexBlock(this.blockIndex, this.exclude, this.brandId)
        },
        modificationsByModels() {
           return this.modifications.reduce((acc, modification) => {
                const { modelId, generationId } = modification;
                // групуємо по моделі
                if(!acc.hasOwnProperty(modelId)) {
                    acc[modelId] = {
                        generations:  {
                            [generationId] : {
                                modifications: modification.modifications
                            }
                        }
                    }
                    return acc
                }
                //групуємо в моделі генерації
                if(!acc[modelId].hasOwnProperty(generationId)) {
                    acc[modelId] = {
                        generations:  {
                            ...acc[modelId].generations,
                            [generationId]: {
                                modifications:  modification.modifications
                            }
                        }
                    }
                }
                // групуємо по генерації
                return acc
            }, {})
        },
        selected() {
            const allModificationIds = this.indices.reduce((acc, seriesIndex) => {
                const series = this.getByIndex({
                    name: 'series',
                    index: seriesIndex
                }) || {};
                if (series.hasOwnProperty('modification')) acc = [...acc, ...series.modification];
                return acc;
            }, []);

            return allModificationIds.length ? allModificationIds : [];
        },
        selectedId() {
            return this.selected.map(el => el.value);
        },
        selectedModificationNames() {
            return Object.values(this.modifications).reduce((acc, el) => {
                el.modifications.forEach(item => {
                    if(this.selectedId.includes(item.value)) acc.push(item.name);
                })
                return acc;
            },[]).join(', ');
        },
        excludePrefix() {
            return this.exclude ? '-not' : '';
        },
        idSelectBackground() {
            return `modification-${this.blockIndex}-${this.brandId}${this.excludePrefix}`;
        },
        idSelect() {
            return `modification-${this.blockIndex}${this.excludePrefix}`;
        },
    },
    methods: {
        ...mapActions('shared/modification',{
            addModificationToSeries: 'addModificationToSeries',
            deleteModificationFromSeries: 'deleteModificationFromSeries'
        }),
        select({ target, modificationData, modelId, generationId }) {
            generationId = Number(generationId);
            modelId = Number(modelId);
            const { name: fieldName } = target; // назва основного поля в обєкті критеріїв пошуку
            const {seriesIndex, ...seriesData} = this.getSeriesDataByModelId(modelId); // данний обьект критеріїв пошуку по (бренду, моделі, року, поколінню)
            const includesModificationInSelected = this.selected.map(el => el.value).includes(modificationData.value)
            // Якщо поля модифікації немає в обекті серії або воно не вказане в полі модифікаціх - то ми додаємо поле
            if(!seriesData.hasOwnProperty(fieldName) || !includesModificationInSelected){
                this.addModificationToSeries({seriesData, seriesIndex, fieldName, generationId, modificationData});
            }
            // В іншому випадку ми видаляємо данну модифікацію - або тільки id або повністю поле
            else {
                this.deleteModificationFromSeries({ seriesData, seriesIndex, fieldName, generationId, modificationData })
            }
        },
        getSeriesDataByModelId(modelId){
            const foundSeries = this.indices.reduce((acc, seriesIndex) => {
                const series = this.getByIndex({
                    name: 'series',
                    index: seriesIndex
                }) || {};

                if(series.hasOwnProperty('model') &&
                    series.model === modelId &&
                    Boolean(this.exclude) === Boolean(series.not)
                ) acc.push({...series, seriesIndex});
                return acc;
            }, []);

            if(!foundSeries.length) return null;
            return foundSeries[0];
        },
        getModelById(modelId){
            return this.generations.find(el => Number(el.id) === Number(modelId));
        },
        getGenerationById({modelId, generationId}){
            const foundModel = this.getModelById(modelId)
            if (!foundModel) return ''

            return foundModel.generations.find(el => Number(el.generationId) === Number(generationId));
        }
    },
    i18n: {
        ...translate
    },
}
