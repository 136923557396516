import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EcoStandart',
  i18n: require('./i18n').default,
  init({ store }) {
    const category = store.getters['search/state2/getCategory'] || 1;
    return store.dispatch('shared/ecoStandarts/fetch', { category });
  },
  computed: {
    ...mapGetters({
      langId: 'lang/id',
      getEcoStandart: 'search/state2/getEcoStandart',
      getCategory: 'search/state2/getCategory',
      ecoStandarts: 'shared/ecoStandarts/ecoStandarts',
    }),
    checkboxes() {
      return this.ecoStandarts({ category: this.category }).map((eco) => {
        eco.checked = this.getEcoStandart.indexOf(eco.value) >= 0;
        return eco;
      });
    },
    category() {
      return this.getCategory || 1;
    },
  },
  watch: {
    langId() {
      this.init();
    },
    category() {
      this.init();
    },
  },
  methods: {
    ...mapActions({
      fetch: 'shared/ecoStandarts/fetch',
      setEcoStandart: 'search/state2/setEcoStandart',
    }),
    toggleChecked(checkbox) {
      this.setEcoStandart({ value: checkbox.value, set: checkbox.checked });
    },
    init() {
      this.fetch({ category: this.category });
    },
  },
  mounted() {
    this.init();
  },
};
